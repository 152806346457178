var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('main-booking-select-organisation'),_c('v-col',{staticClass:"datePicker__wrapper",attrs:{"xl":"2","lg":"3","md":"4","sm":"12","xs":"12"}},[_c('transition',{attrs:{"appear":"","enter-active-class":"animated fadeIn"}},[_c('div',[_c('h2',{staticClass:"steps__wrapper"},[_c('div',{staticClass:"steps__circle"},[_vm._v("1")]),_c('span',[_c('copy',{attrs:{"item":"chooseLocation","lang":_vm.userLang}})],1)]),_c('v-radio-group',{attrs:{"value":_vm.selectedLocation},on:{"change":function($event){return _vm.setLocationForProductSearch({
                value: $event,
              })}}},_vm._l((_vm.orgLocations),function(location,key){return _c('v-radio',{key:location.id,attrs:{"color":"dark","label":location.name.value[_vm.userLang]}})}),1),_c('br'),_c('br')],1)]),(_vm.selectedLocation !== null)?_c('transition',{attrs:{"appear":"","enter-active-class":"animated fadeIn"}},[_c('div',[_c('h2',{staticClass:"steps__wrapper"},[_c('div',{staticClass:"steps__circle"},[_vm._v("2")]),_c('span',[_c('copy',{attrs:{"item":"choosePeriod","lang":_vm.userLang}})],1)]),(_vm.calReady)?_c('main-booking-date-picker',{attrs:{"inline":true}}):_vm._e()],1)]):_vm._e(),(_vm.selectedLocation !== null)?_c('transition',{attrs:{"appear":"","enter-active-class":"animated fadeIn"}},[_c('div',[(
              !_vm.isSelectedStockEmpty &&
              _vm.availableStock &&
              _vm.selectedStock &&
              _vm.bookingDateStart != null
            )?_c('div',[_c('br'),_c('h2',{staticClass:"steps__wrapper"},[_c('div',{staticClass:"steps__circle"},[_vm._v("3")]),_c('span',[_c('copy',{attrs:{"item":"selectTimeSlot","lang":_vm.userLang}})],1)]),(_vm.getOrganisationById(_vm.selectedOrgForBooking).freeTiming)?[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"return-value":_vm.timeStart,"transition":"scale-transition","offset-y":""},on:{"update:returnValue":function($event){_vm.timeStart=$event},"update:return-value":function($event){_vm.timeStart=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.freeTimeBooking.start,"label":"Timing Ophalen","readonly":""}},'v-text-field',attrs,false),on))]}}],null,false,4020948375),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){_vm.setFreeTimeBooking({ time: 'start', value: _vm.timeStart })
                    _vm.$refs.menu1.save(_vm.timeStart)}},model:{value:(_vm.timeStart),callback:function ($$v) {_vm.timeStart=$$v},expression:"timeStart"}})],1),_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"return-value":_vm.timeEnd,"transition":"scale-transition","offset-y":""},on:{"update:returnValue":function($event){_vm.timeEnd=$event},"update:return-value":function($event){_vm.timeEnd=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.freeTimeBooking.end,"label":"Timing terugbrengen","readonly":""},on:{"change":function($event){return _vm.setFreeTimeBooking({ time: 'end', value: $event })}}},'v-text-field',attrs,false),on))]}}],null,false,925812413),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){_vm.setFreeTimeBooking({ time: 'end', value: _vm.timeEnd })
                    _vm.$refs.menu2.save(_vm.timeEnd)}},model:{value:(_vm.timeEnd),callback:function ($$v) {_vm.timeEnd=$$v},expression:"timeEnd"}})],1)]:_c('v-radio-group',{attrs:{"mandatory":false,"value":_vm.selectedTimeSlot},on:{"change":function($event){return _vm.setTimeSlotBooking({
                  value: $event,
                })}}},[_c('v-radio',{attrs:{"color":"dark","label":_vm.timeslots[0].name[_vm.userLang],"disabled":_vm.timeslots[0].disabled}}),_c('v-radio',{attrs:{"color":"dark","label":_vm.timeslots[1].name[_vm.userLang],"disabled":_vm.timeslots[1].disabled}}),_c('v-radio',{attrs:{"color":"dark","label":_vm.timeslots[2].name[_vm.userLang],"disabled":_vm.timeslots[0].disabled === true ||
                  _vm.timeslots[1].disabled === true}})],1)],2):_vm._e()])]):_vm._e()],1),(
        (_vm.selectedTimeSlot !== null ||
          (_vm.freeTimeBooking.start != null && _vm.freeTimeBooking.end != null)) &&
        _vm.selectedLocation !== null
      )?_c('transition',{attrs:{"appear":"","enter-active-class":"animated fadeIn"}},[_c('v-col',{staticClass:"productSelection__wrapper",attrs:{"xl":"7","lg":"6","md":"5","sm":"12","xs":"12","justify":"center"}},[_c('h2',{staticClass:"steps__wrapper"},[_c('div',{staticClass:"steps__circle"},[_vm._v("4")]),_c('span',[_c('copy',{attrs:{"item":"selectProducts","lang":_vm.userLang}})],1)]),_c('div',{staticClass:"productSelection__message"},[(_vm.bookingDateRange.end == null)?_c('span',[_c('copy',{attrs:{"item":"ifPeriodIsSelected","lang":_vm.userLang}})],1):_vm._e()]),_c('v-overlay',{attrs:{"value":_vm.loadingStock}},[_c('app-logo',{staticClass:"animated pulse logoLoading",staticStyle:{"max-width":"400px"},attrs:{"color":"#000"}})],1),_c('div',{staticClass:"productSelection__message"},[(
              _vm.isSelectedStockEmpty &&
              !_vm.loadingStock &&
              _vm.bookingDateRange.end != null
            )?_c('span',[_c('copy',{attrs:{"item":"noProductsFound","lang":_vm.userLang}})],1):_vm._e()]),(
            !_vm.isSelectedStockEmpty &&
            _vm.availableStock &&
            _vm.selectedStock &&
            _vm.bookingDateRange.end != null &&
            (_vm.selectedTimeSlot !== null ||
              (_vm.freeTimeBooking.start != null && _vm.freeTimeBooking.end != null))
          )?_c('div',{staticClass:"productsToSelect__wrapper"},[_c('transition-group',{attrs:{"name":"list-animate","tag":"p"}},_vm._l((_vm.availableStock),function(stock,id){return _c('main-booking-stock-per-product',{key:id,staticClass:"productItem__wrapper list-animate-item",attrs:{"product-id":id,"selected-stock-total":_vm.selectedStock[id].length,"stock-total":stock.length}})}),1)],1):_vm._e()],1)],1):_vm._e(),(
        (_vm.selectedTimeSlot !== null ||
          (_vm.freeTimeBooking.start != null && _vm.freeTimeBooking.end != null)) &&
        _vm.selectedLocation !== null
      )?_c('transition',{attrs:{"appear":"","enter-active-class":"animated fadeIn"}},[_c('v-col',{staticClass:"productsSelected__wrapper",attrs:{"xl":"3","lg":"3","md":"3","sm":"12","xs":"12"}},[_c('h2',{staticClass:"steps__wrapper"},[_c('div',{staticClass:"steps__circle"},[_vm._v("5")]),_c('span',[_c('copy',{attrs:{"item":"createBooking","lang":_vm.userLang}})],1)]),_c('main-booking-product-selection',{staticClass:"productsSelected__module"})],1)],1):_vm._e()],1),_c('main-booking-ordered')],1)}
var staticRenderFns = []

export { render, staticRenderFns }