<template>
  <v-dialog v-model="dialog" persistent>
    <!-- {{ userOrg }} -->

    <template v-slot:activator="{ on }">
      <v-btn
        v-if="!edit"
        class="ma-2"
        outlined
        v-on="on"
        @click="resetUserOrgToEdit"
      >
        <copy item="addOrganisation" :lang="userLang"></copy>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        <copy item="addOrganisation" :lang="userLang"></copy>
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="6" md="6">
                <!-- TODO: Should only be organisations that are not yet added to the user -->
                <v-select
                  :disabled="edit"
                  :items="organisationsFilteredForUser"
                  :label="copy('organisation', userLang)"
                  item-value="id"
                  outlined
                  @input="
                    editSelectedUserOrgToEdit({
                      value: $event,
                      fieldName: 'orgId'
                    })
                  "
                >
                  <template v-slot:selection="{ item, id }">
                    {{ item.name.value[userLang] }}
                  </template>
                  <template v-slot:item="{ item, id }">
                    {{ item.name.value[userLang] }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="6" md="6">
                <v-select
                  :value="selectedUserOrgToEdit.role"
                  item-text="name"
                  :items="userRoles"
                  :label="copy('role', userLang)"
                  item-value="value"
                  outlined
                  @input="
                    editSelectedUserOrgToEdit({
                      value: $event,
                      fieldName: 'role'
                    })
                  "
                >
                  <template v-slot:selection="{ item, id }">
                    {{ item.name[userLang] }}
                  </template>
                  <template v-slot:item="{ item, id }">
                    {{ item.name[userLang] }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          class="ma-2"
          color="error"
          outlined
          @click="cancelAddingOrganisation"
        >
          <copy item="cancel" :lang="userLang"></copy>
        </v-btn>
        <div class="flex-grow-1"></div>
        <template v-if="edit">
          <v-btn class="ma-2" outlined text @click="updateAndClose">
            <copy item="saveUser" :lang="userLang"></copy>
          </v-btn>
        </template>

        <template v-else>
          <v-btn class="ma-2" outlined text @click="addOrganisationAndClose">
            <copy item="add" :lang="userLang"></copy>
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    edit: Boolean,
    userOrg: Object
  },
  data() {
    return {
      dialog: false,
      selectedOrg: null,
      userRole: null
    }
  },
  computed: {
    ...mapState('adminUsers', [
      'userOrganisations',
      'selectedUser',
      'userRoles'
    ]),
    ...mapState('adminOrganisations', ['organisations']),
    ...mapState('authentication', ['user', 'userLang']),
    ...mapState('app', ['networkOnLine']),
    ...mapState('adminUsers', ['selectedUserOrgToEdit', 'selectedUser']),
    ...mapGetters('translations', ['copy']),
    organisationsFilteredForUser(){
      console.log(this.organisations)
      return this.organisations.filter(org => org.id === Object.keys(this.user.organisations)[0])
    }
  },
  methods: {
    ...mapActions('adminUsers', [
      'addUserOrganisations',
      'getUserOrganisations',
      'setUserOrgToEdit',
      'updateOrganisationToUser',
      'addUserOrganisations'
    ]),
    ...mapActions('adminOrganisations', ['getOrganisations']),
    ...mapMutations('adminUsers', [
      'editSelectedUserOrgToEdit',
      'resetUserOrgToEdit',
      'updateUserOrganisation'
    ]),
    cancelAddingOrganisation() {
      this.dialog = false
    },
    addOrganisationAndClose() {
      this.dialog = false
      this.addUserOrganisations({
        orgId: this.selectedUserOrgToEdit.orgId,
        role: this.selectedUserOrgToEdit.role
      })
      // this.addOrganisationToUser({
      //   orgId: this.selectedUserOrgToEdit.orgId,
      //   role: this.selectedUserOrgToEdit.role,
      //   userId: this.selectedUser.id
      // })
    },
    updateAndClose() {
      this.dialog = false
      // this.updateUserOrganisation({orgId: })

      this.updateOrganisationToUser({
        orgId: this.selectedUserOrgToEdit.orgId,
        role: this.selectedUserOrgToEdit.role,
        userId: this.selectedUserOrgToEdit.userId
      })
    }
  },
  created() {
    this.getOrganisations()
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
