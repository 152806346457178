<template>
  <v-dialog
    v-if="organisations && selectedOrgForBooking === null"
    v-model="dialog"
    persistent
  >
    <template v-slot:activator="{ on }"> </template>

    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        <copy item="chooseOrganisation" :lang="userLang"></copy>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <!-- TODO: Should only be organisations that are not yet added to the user -->
              <v-btn
                v-for="(role, org) in user.organisations"
                :key="org"
                class="ma-2"
                :outlined="selectedOrgForBooking != org"
                :dark="selectedOrgForBooking == org"
                @click="setBookingOrg({ org: org, role: role })"
              >
                <template v-if="org">
                  {{ getOrganisationById(org).name.value[userLang] }}
                  <!-- - <i>{{ getUserRoleById(role).name[userLang] }}</i> -->
                </template>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  props: {},
  data() {
    return {
      dialog: true
    }
  },
  computed: {
    ...mapState('adminUsers', []),
    ...mapState('adminOrganisations', ['organisations']),
    ...mapState('adminBookings', ['selectedOrgForBooking']),
    ...mapState('authentication', ['userLang', 'user']),
    ...mapState('app', ['networkOnLine']),
    ...mapGetters('adminOrganisations', ['getOrganisationById']),
    ...mapGetters('adminUsers', ['getUserRoleById'])
  },
  methods: {
    ...mapMutations('adminBookings', ['setSelectedOrgForBooking']),
    ...mapActions('adminOrganisations', ['getOrganisations']),
    ...mapActions('adminBookings', ['startBookingBasedOnOrganisation']),
    cancelGoingToBooking() {
      this.dialog = false
    },
    setBookingOrg({ org: org, role: role }) {
      this.setSelectedOrgForBooking(org)
      this.dialog = false
    }
  },
  created() {
    if (
      Object.keys(this.user.organisations).length > 1 &&
      this.selectedOrgForBooking === null
    ) {
      this.dialog = true
    }
    if (
      Object.keys(this.user.organisations).length === 1 &&
      this.selectedOrgForBooking === null
    ) {
      const [orgId] = Object.keys(this.user.organisations)

      this.setSelectedOrgForBooking(orgId)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
