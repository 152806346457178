<template>
  <v-container fluid>
    <!-- <div class="d-flex d-sm-none">XS</div>
    <div class="d-none d-sm-flex d-md-none">SM</div>
    <div class="d-none d-md-flex d-lg-none">MD</div>
    <div class="d-none d-lg-flex d-xl-none">LG</div>
    <div class="d-none d-xl-flex">XL</div> -->

    <v-row>
      <!-- <v-btn  dark class="mb-2" @click="resetBooking">Reset</v-btn> -->
      <main-booking-select-organisation></main-booking-select-organisation>
      <v-col xl="2" lg="3" md="4" sm="12" xs="12" class="datePicker__wrapper">
        <transition appear enter-active-class="animated fadeIn">
          <div>
            <h2 class="steps__wrapper">
              <div class="steps__circle">1</div>
              <span>
                <copy item="chooseLocation" :lang="userLang"></copy>
              </span>
            </h2>

            <v-radio-group
              :value="selectedLocation"
              @change="
                setLocationForProductSearch({
                  value: $event,
                })
              "
            >
              <v-radio
                v-for="(location, key) in orgLocations"
                :key="location.id"
                color="dark"
                :label="location.name.value[userLang]"
              ></v-radio>
            </v-radio-group>
            <br />
            <br />
          </div>
        </transition>
        <!-- {{ selectedLocation }} -->
        <transition
          v-if="selectedLocation !== null"
          appear
          enter-active-class="animated fadeIn"
        >
          <div>
            <h2 class="steps__wrapper">
              <div class="steps__circle">2</div>
              <span>
                <copy item="choosePeriod" :lang="userLang"></copy>
              </span>
            </h2>

            <main-booking-date-picker
              v-if="calReady"
              :inline="true"
            ></main-booking-date-picker>
          </div>
        </transition>

        <transition
          v-if="selectedLocation !== null"
          appear
          enter-active-class="animated fadeIn"
        >
          <div>
            <div
              v-if="
                !isSelectedStockEmpty &&
                availableStock &&
                selectedStock &&
                bookingDateStart != null
              "
            >
              <br />
              <h2 class="steps__wrapper">
                <div class="steps__circle">3</div>
                <span>
                  <copy item="selectTimeSlot" :lang="userLang"></copy>
                </span>
              </h2>

              <template
                v-if="getOrganisationById(selectedOrgForBooking).freeTiming"
              >
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :return-value.sync="timeStart"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="freeTimeBooking.start"
                      label="Timing Ophalen"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    format="24hr"
                    v-model="timeStart"
                    full-width
                    @click:minute="
                      setFreeTimeBooking({ time: 'start', value: timeStart })
                      $refs.menu1.save(timeStart)
                    "
                  ></v-time-picker>
                </v-menu>

                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="timeEnd"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="freeTimeBooking.end"
                      label="Timing terugbrengen"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @change="
                        setFreeTimeBooking({ time: 'end', value: $event })
                      "
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    format="24hr"
                    v-model="timeEnd"
                    full-width
                    @click:minute="
                      setFreeTimeBooking({ time: 'end', value: timeEnd })
                      $refs.menu2.save(timeEnd)
                    "
                  ></v-time-picker>
                </v-menu>
              </template>
              <v-radio-group
                v-else
                :mandatory="false"
                :value="selectedTimeSlot"
                @change="
                  setTimeSlotBooking({
                    value: $event,
                  })
                "
              >
                <v-radio
                  color="dark"
                  :label="timeslots[0].name[userLang]"
                  :disabled="timeslots[0].disabled"
                ></v-radio>
                <v-radio
                  color="dark"
                  :label="timeslots[1].name[userLang]"
                  :disabled="timeslots[1].disabled"
                ></v-radio>
                <v-radio
                  color="dark"
                  :label="timeslots[2].name[userLang]"
                  :disabled="
                    timeslots[0].disabled === true ||
                    timeslots[1].disabled === true
                  "
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
        </transition>
      </v-col>
      <!-- {{ selectedTimeSlot }} - {{ selectedLocation }}  -->

      <transition
        v-if="
          (selectedTimeSlot !== null ||
            (freeTimeBooking.start != null && freeTimeBooking.end != null)) &&
          selectedLocation !== null
        "
        appear
        enter-active-class="animated fadeIn"
      >
        <v-col
          xl="7"
          lg="6"
          md="5"
          sm="12"
          xs="12"
          justify="center"
          class="productSelection__wrapper"
        >
          <h2 class="steps__wrapper">
            <div class="steps__circle">4</div>
            <span>
              <copy item="selectProducts" :lang="userLang"></copy>
            </span>
          </h2>
          <div class="productSelection__message">
            <span v-if="bookingDateRange.end == null">
              <copy item="ifPeriodIsSelected" :lang="userLang"></copy>
            </span>
          </div>

          <v-overlay :value="loadingStock">
            <app-logo
              v-if=""
              class="animated pulse logoLoading"
              style="max-width: 400px"
              color="#000"
            ></app-logo>
          </v-overlay>

          <div class="productSelection__message">
            <span
              v-if="
                isSelectedStockEmpty &&
                !loadingStock &&
                bookingDateRange.end != null
              "
            >
              <copy item="noProductsFound" :lang="userLang"></copy>
            </span>
          </div>

          <div
            class="productsToSelect__wrapper"
            v-if="
              !isSelectedStockEmpty &&
              availableStock &&
              selectedStock &&
              bookingDateRange.end != null &&
              (selectedTimeSlot !== null ||
                (freeTimeBooking.start != null && freeTimeBooking.end != null))
            "
          >
            <transition-group name="list-animate" tag="p">
              <main-booking-stock-per-product
                v-for="(stock, id) in availableStock"
                :key="id"
                class="productItem__wrapper list-animate-item"
                :product-id="id"
                :selected-stock-total="selectedStock[id].length"
                :stock-total="stock.length"
              ></main-booking-stock-per-product>
            </transition-group>
          </div>
          <!-- TODO: Add fallback if no stock -->
        </v-col>
      </transition>
      <transition
        appear
        enter-active-class="animated fadeIn"
        v-if="
          (selectedTimeSlot !== null ||
            (freeTimeBooking.start != null && freeTimeBooking.end != null)) &&
          selectedLocation !== null
        "
      >
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="12"
          xs="12"
          class="productsSelected__wrapper"
        >
          <h2 class="steps__wrapper">
            <div class="steps__circle">5</div>
            <span>
              <copy item="createBooking" :lang="userLang"></copy>
            </span>
          </h2>
          <main-booking-product-selection
            class="productsSelected__module"
          ></main-booking-product-selection>
        </v-col>
      </transition>
    </v-row>
    <main-booking-ordered></main-booking-ordered>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import mainBookingDatePicker from '@/components/Main-BookingDatePicker'
import MainBookingStockPerProduct from '@/components/Main-BookingStockPerProduct'
import mainBookingProductSelection from '@/components/Main-BookingProductSelection'
import mainBookingOrdered from '@/components/Main-BookingOrdered'
import MainBookingSelectOrganisation from '@/components/Main-BookingSelectOrganisation'
import AppLogo from '@/components/App-Logo'

export default {
  components: {
    mainBookingDatePicker,
    MainBookingStockPerProduct,
    mainBookingProductSelection,
    mainBookingOrdered,
    MainBookingSelectOrganisation,
    AppLogo,
  },
  data() {
    return {
      emptyBookingDate: true,
      timeStart: '',
      timeEnd: '',
      menu1: false,
      menu2: false,
    }
  },
  computed: {
    ...mapState('translations', ['languages', 'translations']),
    ...mapState('authentication', ['userLang']),
    ...mapState('adminOrganisations', ['orgLocations', 'selectedLocation']),
    ...mapState('adminBookings', [
      'calReady',
      'bookingDateRange',
      'availableStock',
      'selectedStock',
      'timeslots',
      'loadingStock',
      'selectedTimeSlot',
      'vCalAttributes',
      'selectedOrgForBooking',
      'freeTimeBooking',
    ]),
    ...mapGetters('adminBookings', ['isSelectedStockEmpty']),
    ...mapGetters('adminOrganisations', ['getOrganisationById']),
    bookingDateStart() {
      return this.bookingDateRange.start
    },
  },
  methods: {
    ...mapActions('adminOrganisations', ['setLocationForProductSearch']),
    ...mapActions('adminBookings', [
      'setTimeSlotBooking',
      'getUnavailableDates',
      'resetBooking',
    ]),
    ...mapMutations('adminOrganisations', ['setSelectedLocation']),
    ...mapMutations('adminBookings', ['setFreeTimeBooking']),
    // checkDisabled(slot, key) {
    //   if(key === 2) {
    //     if(this.timeslots[0].value === true && this.timeslots[1].value === true) return true
    //   } else {
    //     return slot.value
    //   }
    // }
  },
  watch: {
    vCalAttributes: {
      handler: function (newValue, oldValue) {
        if (newValue === null) {
          this.emptyBookingDate = false
        } else {
          this.emptyBookingDate = true
        }
      },
      deep: true,
    },
  },

  mounted() {
    // this.resetBooking();
    this.getUnavailableDates()
  },
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';
@import '@/theme/admin.scss';

.productsToSelect {
  &__wrapper {
    // padding-left: 1rem;
  }
}
.productItem {
  &__wrapper {
    width: 100%;
    margin-bottom: 1rem;
    margin-left: 3rem;
  }
}

.productsSelected {
  &__wrapper {
    padding-left: 2rem;
  }
  &__module {
    padding-left: 1rem;
  }
}
.steps {
  &__wrapper {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
  }

  &__circle {
    margin-right: 1rem;
    background-color: #fffba6;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
}

@media only screen and (max-width: 1264) {
  .datePicker {
    &__wrapper {
      position: fixed;
    }
  }
}
.productSelection {
  &__wrapper {
    // padding-left: 2rem;
    position: relative;
    // padding-right: 2rem;
  }
  &__message {
    width: 100%;
    color: rgba(0, 0, 0, 0.34);
    font-style: italic;
    text-align: center;
  }
}

.list-animate-item {
  transition: all 1s;
  display: inline-block;
  // margin-right: 10px;
}
.list-animate-enter,
.list-animate-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.list-animate-leave-active {
  position: absolute;
}

.v-radio--is-disabled {
  .v-label {
    text-decoration: line-through;
  }
}
</style>
