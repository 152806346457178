<template>
  <div>
    <v-data-table
      v-if="stockListToCreate && stockListToCreate.length"
      :headers="stockToCreateHeaders"
      :items="stockListToCreate"
      :items-per-page="5"
      class="elevation-1"
    ></v-data-table>

    <template v-if="stock && !stock.length" class="infos-label">
      <copy item="noStock" :lang="userLang"></copy>
    </template>

    <v-card>
      <v-card-title>
        <div class="flex-grow-1"></div>
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        v-if="stock && stock.length"
        :headers="stockHeaders"
        :items="stock"
        :items-per-page="20"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:item.createTimestamp="{ item }">
          {{ item.createTimestamp | moment('from', 'now') }}
        </template>
        <template v-slot:item.updateTimestamp="{ item }">
          {{ item.updateTimestamp | moment('from', 'now') }}
        </template>
        <template v-slot:item.location="{ item }">
          {{ getLocationName(item.location, userLang) }}
        </template>

        <template v-slot:item.action="{ item }">
          <v-icon small @click="deletePrompt(item)">
            mdi-delete
          </v-icon>
        </template>

        <template v-slot:item.reference="props">
          <v-edit-dialog
            @save="
              save({ item: props.item, value: editValue, field: 'reference' })
            "
            @cancel="cancel()"
            @open="open()"
            @close="close()"
          >{{ props.item.reference }}
            <template v-slot:input>
              <v-text-field
                :value="props.item.reference"
                label="Edit"
                single-line
                counter
                @input="editValue = $event"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.location="props">
          <v-edit-dialog
            @save="
              save({ item: props.item, value: editValue, field: 'location' })
            "
            @cancel="cancel()"
            @open="open()"
            @close="close()"
          >
          <!-- TODO: Translate -->
          <span v-if="getLocationById(props.item.location) === null">No Location</span>
          <span v-else>{{ getLocationById(props.item.location).name.value[userLang] }}</span>
            
            <template v-slot:input>
              <v-select
                :value="props.item.location.id"
                :items="orgLocations"
                :label="copy('locations', userLang)"
                item-value="location"
                outlined
                @input="editValue = $event.id"
              >
                <template v-slot:selection="{ item, id }">
                  {{ item.name.value[userLang] }} -
                  {{ item.city.value[userLang] }}
                </template>
                <template v-slot:item="{ item, id }">
                  {{ item.name.value[userLang] }} -
                  {{ item.city.value[userLang] }}
                </template>
              </v-select>
            </template>
          </v-edit-dialog>
        </template>

      <template v-slot:item.status="props">
        
          <v-edit-dialog
            @save="
              save({ item: props.item, value: editValue, field: 'status' })
            "
            @cancel="cancel()"
            @open="open()"
            @close="close()"
          >
            <v-chip :color="getStockStatus(props.item.status).color" dark>{{ getStockStatus(props.item.status).description[userLang] }}</v-chip>
            <template v-slot:input>
              <!-- TODO: Translate (Status) -->
              <v-select
                :value="props.item.status.id"
                :items="stockStatus"
                label="Status"
                item-value="status"
                outlined
                @input="editValue = $event.id"
              >
                <template v-slot:selection="{ item, id }">
                  <!-- TODO: This is not appearing in the front end, don't know why -->
                  {{ item.description[userLang] }}
                </template>
                <template v-slot:item="{ item, id }">
                 {{ item.description[userLang] }}
                </template>
              </v-select>
            </template>
          </v-edit-dialog>
        </template>
      </v-data-table>
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}
        <v-btn text @click="snack = false">Close</v-btn>
      </v-snackbar>
    </v-card>

    <v-dialog v-model="dialog" persistent>
      <template v-slot:activator="{ on }">
        <v-btn class="ma-2" outlined v-on="on">
          <copy item="addStock" :lang="userLang"></copy>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <copy item="addStock" :lang="userLang"></copy>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col md="5" sm="12">
                  <v-text-field
                    :label="copy('referenceOfStockItem', userLang)"
                    :value="stockToCreate.reference"
                    outlined
                    @input="
                      setStockToCreate({ field: 'reference', value: $event })
                    "
                  ></v-text-field>
                </v-col>
                <v-col md="5" sm="12">
                  <v-select
                    :value="stockToCreate.location"
                    :items="orgLocations"
                    :label="copy('locations', userLang)"
                    item-value="id"
                    outlined
                    @input="
                      setStockToCreate({ field: 'location', value: $event })
                    "
                  >
                    <template v-slot:selection="{ item, id }">
                      {{ item.name.value[userLang] }} -
                      {{ item.city.value[userLang] }}
                    </template>
                    <template v-slot:item="{ item, id }">
                      {{ item.name.value[userLang] }} -
                      {{ item.city.value[userLang] }}
                    </template>
                  </v-select>
                </v-col>
                <v-col>
                  <v-btn fab outlined text @click="addStockToList">
                    <v-icon small>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <!-- TODO: Translate -->
              <h3>Toe te voegen stock</h3>
              <v-row v-for="(item, key) in stockListToCreate" :key="key">
                <v-col md="5" sm="12">
                  <v-text-field
                    :label="copy('referenceOfStockItem', userLang)"
                    :value="item.reference"
                    outlined
                    @input="
                      setStockItemInListToCreate({
                        id: key,
                        field: 'reference',
                        value: $event
                      })
                    "
                  ></v-text-field>
                </v-col>
                <v-col md="5" sm="12">
                  <v-select
                    :value="getLocationById(item.location)"
                    :items="orgLocations"
                    :label="copy('locations', userLang)"
                    item-value="id"
                    outlined
                    @input="
                      setStockItemInListToCreate({
                        id: key,
                        field: 'location',
                        value: $event
                      })
                    "
                  >
                    <template v-slot:selection="{ item, id }">
                      {{ item.name.value[userLang] }} -
                      {{ item.city.value[userLang] }}
                    </template>
                    <template v-slot:item="{ item, id }">
                      {{ item.name.value[userLang] }} -
                      {{ item.city.value[userLang] }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn class="ma-2" color="error" outlined @click="cancelAddingStock">
            <copy item="cancel" :lang="userLang"></copy>
          </v-btn>
          <div class="flex-grow-1"></div>
          <v-btn class="ma-2" outlined text @click="createStockAndClose">
            <copy item="add" :lang="userLang"></copy>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  data() {
    return {
      dialog: false,
      search: '',
      editValue: '',
      snack: false,
      snackColor: '',
      snackText: ''
    }
  },
  computed: {
    ...mapState('adminProducts', [
      'stock',
      'stockHeaders',
      'stockToCreateHeaders',
      'productToCreate',
      'stockToCreate',
      'stockListToCreate',
      'stockStatus'
    ]),
    ...mapState('translations', ['languages', 'translations']),
    ...mapState('authentication', ['userLang']),
    ...mapState('app', ['networkOnLine']),
    ...mapState('adminOrganisations', ['orgLocations']),
    ...mapGetters('adminOrganisations', ['getLocationById']),
    ...mapGetters('adminProducts', ['getStockStatus']),
    ...mapGetters('translations', ['copy'])
  },
  methods: {
    ...mapMutations('adminProducts', [
      'setStockToCreate',
      'setStockItemInListToCreate',
      'setStockListToCreate',
      'resetStockToCreate'
    ]),
    ...mapActions('adminProducts', [
      'getHeaders',
      'addStockToStockToCreateArray',
      'createStock',
      'updateStockItem',
      'deleteStock'
    ]),
    ...mapActions('adminOrganisations', ['getLocations']),
    cancelAddingStock() {
      this.dialog = false
      this.setStockListToCreate([])
      this.resetStockToCreate()
    },
    addStockToList() {
      this.addStockToStockToCreateArray(this.stockToCreate)
    },
    createStockAndClose() {
      if (this.stockListToCreate.length === 0) {
        this.addStockToStockToCreateArray(this.stockToCreate)
      }
      this.createStock({
        stockList: this.stockListToCreate,
        productId: this.productToCreate.id
      })
      this.dialog = false
    },
    save({ item: item, value: value, field: field }) {
      console.log(item, value, field)
      this.updateStockItem({
        productId: this.productToCreate.id,
        stockItem: item,
        value: value,
        field: field
      })
      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'Data saved'
    },
    cancel() {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    },
    open() {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Dialog opened'
    },
    close() {
      this.editValue = ''
    },
    getLocationName(locationId, userLang) {
      if (this.orgLocations) {
        const location = this.getLocationById(locationId)
        if (location !== undefined) {
          return location.name.value[userLang]
        } else {
          return 'not found'
        }
      }
    },
    deletePrompt(item) {
      confirm('Delete Stock?') &&
        this.deleteStock({ stockId: item.id, productId: item.productId })
    }
  },
  created() {
    this.getLocations({
      organisationId: this.productToCreate.default.org.value
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.infos-label {
  text-align: center;
}

.product-row {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 10px auto;
  justify-content: space-between;
}
</style>
