<template>
  <div class="selection__wrapper">
    
    <div v-if="bookingDateRange.end != null && selectedTimeSlot !== null" class="selection__timing">
      <span style="text-transform: capitalize">
        <p>{{ bookingDateRange.start | moment('dddd DD MMMM YYYY') }}, {{ timeslots[selectedTimeSlot].name[userLang]}}</p>

        <p v-if="bookingDateRange.end && bookingDateRange.end != bookingDateRange.start">{{ bookingDateRange.end | moment('dddd DD MMMM YYYY') }}, {{ timeslots[selectedTimeSlot].name[userLang]}}</p>
       </span>
      
      <!-- <div class="selection__date">
        <span><copy item="start" :lang="userLang"></copy>:</span>
        <span>{{ bookingDateRange.start | moment('dddd, MMMM Do YYYY') }}</span>
      </div>
      <div v-if="dateMode !== null" class="selection__date">
        <span><copy item="end" :lang="userLang"></copy>:</span>
        <span>{{ bookingDateRange.end | moment('dddd, MMMM Do YYYY') }}</span>
      </div>
      <div v-if="selectedTimeSlot !== null" class="selection__date">
        <span><copy item="moment" :lang="userLang"></copy>:</span>
        <span>{{ timeslots[selectedTimeSlot].name[userLang] }}</span>
      </div> -->
    </div>
    <v-divider class="selection__divider"></v-divider>
    <!-- {{ selectedStock }}
    <div v-for="(stock, id) in selectedStock" :key="id">
      {{ stock }}
    </div> -->    

    <main-selected-product
      v-for="(stock, id) in selectedStock"
      :key="id"
      :product="id"
      :stock="stock"
    ></main-selected-product>

    <v-divider class="selection__divider"></v-divider>
    <div class="selection__total">
      <span><copy item="total" :lang="userLang"></copy>:</span> <span>{{ totalSelection }}</span>
    </div>

    
    <v-btn
      :disabled="totalSelection == 0"
      color="primary"
      elevation="0"
      class="selection__button"
      block
      @click="
        createBooking({
          dateRange: bookingDateRange,
          selectedStock: selectedStock,
          totalProducts: totalSelection,
          editing: editingBooking
        })
      "
      ><span style="color:#2c3e50"><copy item="createBooking" :lang="userLang"></copy></span
      ></v-btn
    >
    <br>
    
    <!-- <v-alert
      v-if="!isSelectedStockEmpty && availableStock && selectedStock && bookingDateRange.end != null && selectedTimeSlot === null"
      dense
      border="left"
      type="warning"
    >
      <span><copy item="selectTimeSlot" :lang="userLang"></copy>:</span>
    </v-alert> -->
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import MainSelectedProduct from '@/components/Main-SelectedProduct'

export default {
  components: { MainSelectedProduct },
  computed: {
    ...mapState('authentication', ['userLang']),
    ...mapGetters('adminBookings', ['getTotalSelection']),
    ...mapState('adminBookings', [
      'bookingDateRange',
      'selectedStock',
      'editingBooking',
      'dateMode',
      'timeslots',
      'selectedTimeSlot'
    ]),
    totalSelection() {
      return this.getTotalSelection(this.selectedStock)
    }
  },
  methods: {
    ...mapActions('adminBookings', ['createBooking'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/admin.scss';

.selection {
  &__wrapper {
    padding-right: 2rem;
    position: relative;
  }
  &__date {
    display: flex;
    justify-content: space-between;
  }
  &__button {
    margin-top: 2rem;
  }
  &__divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  &__total {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 1.2rem;
  }
}
</style>
