<template>
  <v-container fluid>
    <v-row align="start" justify="start" class="grey lighten-5">
      <v-col cols="12" sm="12" md="12">
        <template>
          <v-card>
            <v-card-title>
              <!-- Translate -->
              <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn  dark class="mb-2" v-on="on"><copy item="addTranslation" :lang="userLang"></copy></v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline"><copy item="addTranslation" :lang="userLang"></copy></span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field :value="newTranslation.ref" @input="setNewTranslation({value: $event, field: 'ref', key: null})" label="Reference"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field :value="newTranslation.value[0]" @input="setNewTranslation({value: $event, field: 'value', key: 0})" label="NL"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field :value="newTranslation.value[1]" @input="setNewTranslation({value: $event, field: 'value', key: 1})" label="FR"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="saveNew">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
              <div class="flex-grow-1"></div>
              <v-text-field
                v-model="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :search="search"
              :headers="translationHeaders"
              :items="translations"
              :items-per-page="20"
              class="elevation-1"
            >
              <template v-slot:item.value[0]="props">
                <v-edit-dialog
                  @save="
                    save({ value: editValue, lang: 0, ref: props.item.ref })
                  " 
                  @cancel="cancel()"
                  @open="open()"
                  @close="close()"
                >
                  {{ props.item.value[0] }}
                  <template v-slot:input>
                    <v-text-field
                      :value="props.item.value[0]"
                      label="Edit"
                      single-line
                      counter
                      @input="editValue = $event"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:item.value[1]="props">
                <v-edit-dialog
                  @save="
                    save({ value: editValue, lang: 1, ref: props.item.ref })
                  "
                  @cancel="cancel()"
                  @open="open()"
                  @close="close()"
                >
                  {{ props.item.value[1] }}
                  <template v-slot:input>
                    <v-text-field
                      :value="props.item.value[1]"
                      label="Edit"
                      single-line
                      counter
                      @input="editValue = $event"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
            </v-data-table>

            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
              {{ snackText }}
              <v-btn text @click="snack = false">Close</v-btn>
            </v-snackbar>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState('app', ['networkOnLine']),
    ...mapState('authentication', ['userLang']),
    ...mapState('translations', [
      'translations',
      'translationHeaders',
      'languages',
      'newTranslation'
    ])
  },
  data() {
    return {
      dialog: false,
      search: '',
      editValue: '',
      snack: false,
      snackColor: '',
      snackText: ''
    }
  },
  methods: {
    ...mapActions('translations', ['saveTranslation', 'addTranslation']),
    ...mapMutations('translations', ['setNewTranslation', 'resetNewTranslation']),
    closeDialog(){
      console.log('here')
      this.dialog = false;
      this.resetNewTranslation()
    },
    saveNew(){
      this.addTranslation({translation: this.newTranslation})
      this.closeDialog()
    },
    save({ value: value, lang: lang, ref: ref }) {
      this.saveTranslation({ value: value, lang: lang, ref: ref })
      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'Data saved'
    },
    cancel() {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
      this.editValue = ''
    },
    open() {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Dialog opened'
    },
    close() {
      this.editValue = ''
    }
  }
}
</script>
