<template>
  <div class="datepicker__wrapper">
    <div v-if="editingBooking !== null" class="datepicker__disabled">
      <copy item="changingPeriodNotPossible" :lang="userLang"></copy>
    </div>

    <div v-if="selectedLocation === null" class="datepicker__disabled">
      <copy item="firstSelectLocation" :lang="userLang"></copy>
    </div>
    <velov-date-picker
      v-if="dateMode != 'range'"
      readonly
      is-expanded
      :value="bookingDateRange"
      :locale="getLanguage(userLang).locale"
      :disabled-dates="vCalDisabledDates"
      :attributes="attributes"
      :theme="theme"
      :is-inline="inline"
      :mode="dateMode"
      @input="checkDateForBooking({ dateRange: $event, type: 'single' })"
    >
    </velov-date-picker>

    <velov-date-picker
      v-if="dateMode == 'range'"
      readonly
      is-expanded
      mode="range"
      @input="checkDateForBooking({ dateRange: $event, type: 'range' })"
      @drag="isDragging"
      :value="bookingDateRange.start"
      :locale="getLanguage(userLang).locale"
      :disabled-dates="disabledDates"
      :attributes="attributes"
      :theme="theme"
      :is-inline="inline"
    ></velov-date-picker>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import moment from 'moment'
// import AdminProductsSpecAddDialog from '@/components/Admin-ProductsSpecAddDialog'

export default {
  // components: { AdminProductsSpecAddDialog },
  props: {
    inline: Boolean,
  },
  computed: {
    ...mapState('adminBookings', [
      'bookingDateRange',
      'editingBooking',
      'vCalAttributes',
      'dateMode',
      'vCalDisabledDates',
    ]),
    ...mapState('adminOrganisations', ['selectedLocation']),
    ...mapState('translations', ['languages', 'translations']),
    ...mapState('authentication', ['userLang']),
    ...mapGetters('translations', ['getLanguage']),
    disabledDates() {
      if (this.dragValue) {
        return null
      } else {
        return this.vCalDisabledDates
      }
    },
  },
  data() {
    return {
      dragValue: null,
      attributes: [],
      theme: {
        dayContentDisabled: 'vc-pointer-events-none velo-disabled',
        // dayContent: 'velo-normalDay',
        weekdays: {
          color: '#6eded1', // New color
          fontWeight: '600', // And bolder font weight
        },
        container: {
          // INFO: https://github.com/nathanreyes/v-calendar/blob/next/src/utils/defaults/theme.js
          light: 'light-container-class',
          dark: 'dark-container-class',
        },
        bgAccentLow: {
          light: 'dt-range__selected',
        },
        // bgAccentHigh: {
        //   light: 'dt__range__beginEnd',
        //   dark: ''
        // }
      },
    }
  },
  methods: {
    ...mapActions('adminBookings', ['checkDateForBooking']),
    isDragging: function (event) {
      this.dragValue = event

      if (event !== null) {
        if (moment(event.start).isSame(moment(event.end))) {
        } else if (moment(event.start).isBefore(moment(event.end))) {
        } else if (moment(event.start).isAfter(momentmoment(event.end))) {
        }
        // this.checkDateForBooking(event, 'range')
      } else {
      }
    },
  },
  watch: {
    vCalAttributes(newValue, oldValue) {
      this.attributes = newValue
    },
    vCalDisabledDates(newValue, oldValue) {
      return newValue
      // this.disabledDates = newValue;
    },
  },
  mounted() {
    this.attributes = this.vCalAttributes
  },
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';
@import '@/theme/admin.scss';

.datepicker {
  &__wrapper {
    position: relative;
  }
  &__disabled {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    opacity: 0.9;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.vc-bg-blue-600 {
  background-color: rgb(238, 42, 123);
  color: white !important;
}

.velo-normalDay {
  background: #beffec;
  border-radius: 50%;
  cursor: pointer;
}

.velo-today {
  background: #fffba6 !important;
  position: relative;
  z-index: 1000;
  // border: rgb(238, 42, 123);
  // border-style: solid;
  // border-width: 1px;
}

.velo-disabled {
  background: none;
  opacity: 0.3;
}
.vc-grid-container {
  // background: linear-gradient(to bottom right, #25266c, #343698);
  // border-radius: 10px;

  .vc-header {
    color: #dadada;
    padding: 20px 15px 10px 15px;
  }
  .vc-title {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
  .vc-arrow {
    color: #7d799b;
    margin-top: 10px;
  }
  .vc-weeks {
    padding: 0;
  }
  .vc-weekdays {
    color: #2ecdba;
    font-size: 0.8rem;
    font-weight: 700;
    padding: 10px 0;
  }
  .vc-day-content {
    font-size: 0.9rem;
    font-weight: 500;
    margin: 8px 8px;
    color: rgba(0, 0, 0, 0.87);
    position: relative;
    &::selection {
      color: white;
    }

    // z-index: ;
  }
}

.vc-highlights {
  // z-index: 0 !important;
}
.dt__range__beginEnd {
  background-color: rgb(238, 42, 123);
  color: white;
}
.dt-range__selected {
  background-color: #beffec;
}

.vc-text-white {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

.datePicked-before {
  background-color: rgb(250, 250, 250);
  clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);
  position: relative;
  z-index: 2000;
}

.datePicked-after {
  clip-path: polygon(100% 0, 50% 0, 50% 100%, 100% 100%);
  background-color: rgb(250, 250, 250);
  position: relative;
  z-index: 2000;
  // background-color: rgba(190, 255, 236, 1);
}

.vc-day-content::selection {
  background-color: rgb(238, 42, 123) !important;
  // color: white;
}

.velo-selected {
  border-radius: 50%;
  background-color: rgb(238, 42, 123) !important;
  color: white;
}
</style>
