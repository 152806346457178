<template>
  <v-container fluid>
    <v-row align="start" justify="start" class="grey lighten-5">
      <v-col cols="12" sm="12" md="4" xl="2">
        <admin-products-list></admin-products-list>
      </v-col>
      <v-col v-if="productFunction != null" cols="12" sm="12" md="8" xl="10">
        <admin-products-detail v-if="networkOnLine"></admin-products-detail>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import AdminProductsList from '@/components/Admin-ProductsList'
import AdminProductsDetail from '@/components/Admin-ProductsDetail'

export default {
  components: { AdminProductsList, AdminProductsDetail },
  computed: {
    ...mapState('app', ['networkOnLine']),
    ...mapState('adminProducts', ['productFunction'])
  }
}
</script>
