<template>
  <div>
    <template>
      <v-card>
        <v-card-title>
          <!-- Translate -->
          <!-- <v-dialog v-model="dialog" max-width="500px"> -->
          <!-- <template v-slot:activator="{ on }">
            <v-btn  dark class="mb-2" v-on="on"><copy item="addTranslation" :lang="userLang"></copy></v-btn>
          </template> -->
          <!-- <v-card>
            <v-card-title>
              <span class="headline"><copy item="addTranslation" :lang="userLang"></copy></span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field :value="newTranslation.ref" @input="setNewTranslation({value: $event, field: 'ref', key: null})" label="Reference"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field :value="newTranslation.value[0]" @input="setNewTranslation({value: $event, field: 'value', key: 0})" label="NL"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field :value="newTranslation.value[1]" @input="setNewTranslation({value: $event, field: 'value', key: 1})" label="FR"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="saveNew">Save</v-btn>
            </v-card-actions>
          </v-card> -->
          <!-- </v-dialog> -->
          <div class="flex-grow-1"></div>
          <v-text-field
            v-model="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="bookingHeaders"
          :items="activeBookingsList"
          :items-per-page="20"
          multi-sort
          class="elevation-1"
        >
          <template v-slot:item.value[0]="props">
            <v-edit-dialog
              @save="save({ value: editValue, lang: 0, ref: props.item.ref })"
              @cancel="cancel()"
              @open="open()"
              @close="close()"
            >
              {{ props.item.value[0] }}
              <template v-slot:input>
                <v-text-field
                  :value="props.item.value[0]"
                  label="Edit"
                  single-line
                  counter
                  @input="editValue = $event"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:item.value[1]="props">
            <v-edit-dialog
              @save="save({ value: editValue, lang: 1, ref: props.item.ref })"
              @cancel="cancel()"
              @open="open()"
              @close="close()"
            >
              {{ props.item.value[1] }}
              <template v-slot:input>
                <v-text-field
                  :value="props.item.value[1]"
                  label="Edit"
                  single-line
                  counter
                  @input="editValue = $event"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:item.dateRange="{ item }">
            {{ item.dateRange.start | moment('D MMMM YYYY') }}
            <span v-if="!isSameDate(item.dateRange.start, item.dateRange.end)"
              >- {{ item.dateRange.end | moment('D MMMM YYYY') }}</span
            >
          </template>

          <template v-slot:item.userId="{ item }">
            <span v-if="getUserById(item.userId)">
              {{ getUserById(item.userId).firstName.value }}
              {{ getUserById(item.userId).lastName.value }}
            </span>
            <!-- ({{xitem.dateRange.start | moment('from', 'now') }}) -->
          </template>

          <template v-slot:item.school="{ item }">
            <span v-if="getUserById(item.userId)">
              {{ getUserById(item.userId).school.value }}
            </span>
            <!-- ({{xitem.dateRange.start | moment('from', 'now') }}) -->
          </template>

          <template v-slot:item.locations="{ item }">
            <div v-for="location in item.locations" :key="location">
              {{ getLocationById(location).name.value[userLang] }}
            </div>
          </template>

          <template v-slot:item.toDelete="{ item }">
            <span v-if="item.toDelete">{{ item.toDelete }}</span>
            <!-- <div v-for="location in item.locations" :key="location">
                  {{ getLocationById(location).name.value[userLang]}}
                </div> -->
          </template>

          <template v-slot:item.timeslots="{ item }">
            <div
              v-for="(timeslot, key) in item.timeslots"
              :key="`timeslot-${key}`"
            >
              <span v-if="timeslot.value">
                {{ timeslot.name[userLang] }}
              </span>
            </div>
          </template>

          <template v-slot:item.selectedStock="{ item }">
            <div v-for="(stock, product) in item.selectedStock" :key="product">
              <div v-if="getProductById(product)">
                {{ getProductById(product).default.name.value[userLang] }}:
                {{ stock.length }}
              </div>
            </div>
          </template>

          <template v-slot:item.action="{ item }">
            <v-icon small @click="deletePrompt(item)"> mdi-delete </v-icon>
          </template>
          <!-- <template v-slot:item.name="{ item }">
                <span v-if="getOrganisationById(item.orgId)">
                  {{ getOrganisationById(item.orgId).name.value[userLang] }}
                </span>
                <span v-else>
                  {{ item.orgId }}
                </span>
              </template> -->
        </v-data-table>

        <!-- <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
              {{ snackText }}
              <v-btn text @click="snack = false">Close</v-btn>
            </v-snackbar> -->
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  computed: {
    ...mapGetters('adminUsers', ['getUserById']),
    ...mapGetters('adminOrganisations', ['getLocationById']),
    ...mapGetters('adminProducts', ['getProductById']),
    ...mapState('adminBookings', ['activeBookingsList', 'bookingHeaders']),
    ...mapState('authentication', ['userLang']),
    ...mapState('app', ['networkOnLine']),
  },
  data() {
    return {
      dialog: false,
      search: '',
      // editValue: '',
      // snack: false,
      // snackColor: '',
      // snackText: ''
    }
  },
  methods: {
    ...mapActions('adminBookings', [
      'getAllBookings',
      'listenForBookingUpdates',
      'cancelBooking',
    ]),
    ...mapActions('adminUsers', ['getUsers']),
    deletePrompt(item) {
      confirm('Cancel Booking?') && this.cancelBooking({ bookingId: item.id })
    },
    isSameDate(date1, date2) {
      return moment(date1).isSame(moment(date2))
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index === 'asfds') {
          if (!isDesc) {
            return dateHelp.compare(a.date, b.date)
          } else {
            return dateHelp.compare(b.date, a.date)
          }
        } else {
          if (!isDesc) {
            return a[index] < b[index] ? -1 : 1
          } else {
            return b[index] < a[index] ? -1 : 1
          }
        }
      })
      return items
    },
  },
  mounted() {
    this.getAllBookings()
    this.listenForBookingUpdates()
    // TODO: Should this go to a more main "admin" function?
    this.getUsers()
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
