var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":""},scopedSlots:_vm._u([(_vm.edit)?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.setLocationToEdit(_vm.location)}}},on),[_vm._v("\n      mdi-pencil\n    ")])]}}:{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"outlined":""},on:{"click":_vm.setLocationToCreateToDefaults}},on),[_c('copy',{attrs:{"item":"addLocation","lang":_vm.userLang}})],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_c('copy',{attrs:{"item":"addLocation","lang":_vm.userLang}})],1),_c('v-card-text',[_c('v-form',[_c('v-container',[_c('v-row',_vm._l((_vm.locationToCreate),function(field,fieldName){return (
                fieldName != 'createTimestamp' ||
                  fieldName != 'updateTimeStamp'
              )?_c('v-col',{key:'default-' + fieldName,attrs:{"cols":"12","sm":"12","md":"12"}},[(field.i18n && field.label != undefined)?[_c('p',{staticClass:"admin-form__label"},[_vm._v(_vm._s(field.label[_vm.userLang]))]),_vm._l((_vm.languages),function(l,lang){return _c('v-textarea',{key:lang,attrs:{"label":l.name,"placeholder":field.label[_vm.userLang],"value":_vm.locationToCreate[fieldName].value[lang],"outlined":""},on:{"input":function($event){return _vm.setLocationToCreate({
                      value: $event,
                      fieldName: fieldName,
                      lang: lang
                    })}}})})]:(field.label != undefined)?[_c('p',{staticClass:"admin-form__label"},[_vm._v(_vm._s(field.label[_vm.userLang]))]),_c('v-textarea',{attrs:{"label":field.label[_vm.userLang],"placeholder":field.label[_vm.userLang],"value":_vm.locationToCreate[fieldName].value,"outlined":""},on:{"input":function($event){return _vm.setLocationToCreate({
                      value: $event,
                      fieldName: fieldName,
                      lang: null
                    })}}})]:_vm._e()],2):_vm._e()}),1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"error","outlined":""},on:{"click":_vm.cancelAddingLocation}},[_c('copy',{attrs:{"item":"cancel","lang":_vm.userLang}})],1),_c('div',{staticClass:"flex-grow-1"}),(_vm.edit)?_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","text":""},on:{"click":_vm.updateLocationAndClose}},[_c('copy',{attrs:{"item":"saveProduct","lang":_vm.userLang}})],1):_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","text":""},on:{"click":_vm.createLocationAndClose}},[_c('copy',{attrs:{"item":"add","lang":_vm.userLang}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }