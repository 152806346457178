var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-form',[_c('v-container',[_c('v-row',{attrs:{"align":"center"}},_vm._l((_vm.orgToCreate),function(field,fieldName){return _c('v-col',{key:'organisation-' + fieldName,attrs:{"cols":"12","sm":"12","md":"12"}},[(field.label)?[_c('p',{staticClass:"admin-form__label"},[_vm._v(_vm._s(field.label[_vm.userLang]))]),_vm._l((_vm.languages),function(l,lang){return _c('v-text-field',{key:'organisationlang-' + lang,attrs:{"label":l.name,"placeholder":field.label[_vm.userLang],"value":_vm.orgToCreate[fieldName].value[lang],"outlined":""},on:{"input":function($event){return _vm.setOrganisationToCreate({
                  value: $event,
                  fieldName: fieldName,
                  lang: lang
                })}}})})]:_vm._e()],2)}),1),_c('h3',[_vm._v("Mails")]),_vm._l((_vm.orgToCreate.mails),function(mail,mailId){return _c('v-row',{key:'mails-' + mailId,attrs:{"align":"center"}},_vm._l((mail),function(field,fieldName){return _c('v-col',{key:'mailfield-' + fieldName,attrs:{"cols":"12","sm":"12","md":"12"}},[(fieldName == 'name')?[_c('h4',[_vm._v("Verstuurd bij: "+_vm._s(field[_vm.userLang]))])]:(fieldName == 'descr')?[_c('p',[_vm._v(_vm._s(field[_vm.userLang]))])]:(fieldName == 'subject')?_vm._l((_vm.languages),function(l,lang){return _c('v-text-field',{key:'subject' + lang + fieldName + lang,attrs:{"label":fieldName,"placeholder":fieldName,"value":_vm.orgToCreate.mails[mailId].subject.value[lang],"outlined":""},on:{"input":function($event){return _vm.setMailForOrganisation({
                  value: $event,
                  fieldName: fieldName,
                  lang: lang,
                  mailId: mailId,
                  partId: null
                })}}})}):(fieldName == 'parts')?[_vm._l((mail.parts),function(part,partId){return _vm._l((_vm.languages),function(l,lang){return _c('v-text-field',{key:'mailpart' + partId + lang,attrs:{"label":part.label[lang],"placeholder":fieldName,"value":_vm.orgToCreate.mails[mailId].parts[partId].value[lang],"outlined":""},on:{"input":function($event){return _vm.setMailForOrganisation({
                    value: $event,
                    fieldName: fieldName,
                    lang: lang,
                    mailId: mailId,
                    partId: partId
                  })}}})})})]:_vm._e()],2)}),1)}),_c('admin-organisations-locations')],2)],1),(_vm.orgFunction == 'edit')?[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","text":""},on:{"click":_vm.saveOrg}},[_c('copy',{attrs:{"item":"saveOrganisation","lang":_vm.userLang}})],1)]:_vm._e(),(_vm.orgFunction == 'create')?[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","text":""},on:{"click":_vm.addOrg}},[_c('copy',{attrs:{"item":"addOrganisation","lang":_vm.userLang}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }