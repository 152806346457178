var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.stockListToCreate && _vm.stockListToCreate.length)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.stockToCreateHeaders,"items":_vm.stockListToCreate,"items-per-page":5}}):_vm._e(),(_vm.stock && !_vm.stock.length)?[_c('copy',{attrs:{"item":"noStock","lang":_vm.userLang}})]:_vm._e(),_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.stock && _vm.stock.length)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.stockHeaders,"items":_vm.stock,"items-per-page":20,"search":_vm.search},scopedSlots:_vm._u([{key:"item.createTimestamp",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm._f("moment")(item.createTimestamp,'from', 'now'))+"\n      ")]}},{key:"item.updateTimestamp",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm._f("moment")(item.updateTimestamp,'from', 'now'))+"\n      ")]}},{key:"item.location",fn:function(props){return [_c('v-edit-dialog',{on:{"save":function($event){return _vm.save({ item: props.item, value: _vm.editValue, field: 'location' })},"cancel":function($event){return _vm.cancel()},"open":function($event){return _vm.open()},"close":function($event){return _vm.close()}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{attrs:{"value":props.item.location.id,"items":_vm.orgLocations,"label":_vm.copy('locations', _vm.userLang),"item-value":"location","outlined":""},on:{"input":function($event){_vm.editValue = $event.id}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var id = ref.id;
return [_vm._v("\n                "+_vm._s(item.name.value[_vm.userLang])+" -\n                "+_vm._s(item.city.value[_vm.userLang])+"\n              ")]}},{key:"item",fn:function(ref){
var item = ref.item;
var id = ref.id;
return [_vm._v("\n                "+_vm._s(item.name.value[_vm.userLang])+" -\n                "+_vm._s(item.city.value[_vm.userLang])+"\n              ")]}}],null,true)})]},proxy:true}],null,true)},[(_vm.getLocationById(props.item.location) === null)?_c('span',[_vm._v("No Location")]):_c('span',[_vm._v(_vm._s(_vm.getLocationById(props.item.location).name.value[_vm.userLang]))])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deletePrompt(item)}}},[_vm._v("\n          mdi-delete\n        ")])]}},{key:"item.reference",fn:function(props){return [_c('v-edit-dialog',{on:{"save":function($event){return _vm.save({ item: props.item, value: _vm.editValue, field: 'reference' })},"cancel":function($event){return _vm.cancel()},"open":function($event){return _vm.open()},"close":function($event){return _vm.close()}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"value":props.item.reference,"label":"Edit","single-line":"","counter":""},on:{"input":function($event){_vm.editValue = $event}}})]},proxy:true}],null,true)},[_vm._v(_vm._s(props.item.reference)+"\n          ")])]}},{key:"item.status",fn:function(props){return [_c('v-edit-dialog',{on:{"save":function($event){return _vm.save({ item: props.item, value: _vm.editValue, field: 'status' })},"cancel":function($event){return _vm.cancel()},"open":function($event){return _vm.open()},"close":function($event){return _vm.close()}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{attrs:{"value":props.item.status.id,"items":_vm.stockStatus,"label":"Status","item-value":"status","outlined":""},on:{"input":function($event){_vm.editValue = $event.id}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var id = ref.id;
return [_vm._v("\n                "+_vm._s(item.description[_vm.userLang])+"\n              ")]}},{key:"item",fn:function(ref){
var item = ref.item;
var id = ref.id;
return [_vm._v("\n               "+_vm._s(item.description[_vm.userLang])+"\n              ")]}}],null,true)})]},proxy:true}],null,true)},[_c('v-chip',{attrs:{"color":_vm.getStockStatus(props.item.status).color,"dark":""}},[_vm._v(_vm._s(_vm.getStockStatus(props.item.status).description[_vm.userLang]))])],1)]}}],null,false,1653450318)}):_vm._e(),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v("\n      "+_vm._s(_vm.snackText)+"\n      "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},[_vm._v("Close")])],1)],1),_c('v-dialog',{attrs:{"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"outlined":""}},on),[_c('copy',{attrs:{"item":"addStock","lang":_vm.userLang}})],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_c('copy',{attrs:{"item":"addStock","lang":_vm.userLang}})],1),_c('v-card-text',[_c('v-form',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"md":"5","sm":"12"}},[_c('v-text-field',{attrs:{"label":_vm.copy('referenceOfStockItem', _vm.userLang),"value":_vm.stockToCreate.reference,"outlined":""},on:{"input":function($event){return _vm.setStockToCreate({ field: 'reference', value: $event })}}})],1),_c('v-col',{attrs:{"md":"5","sm":"12"}},[_c('v-select',{attrs:{"value":_vm.stockToCreate.location,"items":_vm.orgLocations,"label":_vm.copy('locations', _vm.userLang),"item-value":"id","outlined":""},on:{"input":function($event){return _vm.setStockToCreate({ field: 'location', value: $event })}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var id = ref.id;
return [_vm._v("\n                    "+_vm._s(item.name.value[_vm.userLang])+" -\n                    "+_vm._s(item.city.value[_vm.userLang])+"\n                  ")]}},{key:"item",fn:function(ref){
var item = ref.item;
var id = ref.id;
return [_vm._v("\n                    "+_vm._s(item.name.value[_vm.userLang])+" -\n                    "+_vm._s(item.city.value[_vm.userLang])+"\n                  ")]}}])})],1),_c('v-col',[_c('v-btn',{attrs:{"fab":"","outlined":"","text":""},on:{"click":_vm.addStockToList}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("\n                    mdi-plus\n                  ")])],1)],1)],1),_c('h3',[_vm._v("Toe te voegen stock")]),_vm._l((_vm.stockListToCreate),function(item,key){return _c('v-row',{key:key},[_c('v-col',{attrs:{"md":"5","sm":"12"}},[_c('v-text-field',{attrs:{"label":_vm.copy('referenceOfStockItem', _vm.userLang),"value":item.reference,"outlined":""},on:{"input":function($event){return _vm.setStockItemInListToCreate({
                      id: key,
                      field: 'reference',
                      value: $event
                    })}}})],1),_c('v-col',{attrs:{"md":"5","sm":"12"}},[_c('v-select',{attrs:{"value":_vm.getLocationById(item.location),"items":_vm.orgLocations,"label":_vm.copy('locations', _vm.userLang),"item-value":"id","outlined":""},on:{"input":function($event){return _vm.setStockItemInListToCreate({
                      id: key,
                      field: 'location',
                      value: $event
                    })}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var id = ref.id;
return [_vm._v("\n                    "+_vm._s(item.name.value[_vm.userLang])+" -\n                    "+_vm._s(item.city.value[_vm.userLang])+"\n                  ")]}},{key:"item",fn:function(ref){
                    var item = ref.item;
                    var id = ref.id;
return [_vm._v("\n                    "+_vm._s(item.name.value[_vm.userLang])+" -\n                    "+_vm._s(item.city.value[_vm.userLang])+"\n                  ")]}}],null,true)})],1)],1)})],2)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"error","outlined":""},on:{"click":_vm.cancelAddingStock}},[_c('copy',{attrs:{"item":"cancel","lang":_vm.userLang}})],1),_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","text":""},on:{"click":_vm.createStockAndClose}},[_c('copy',{attrs:{"item":"add","lang":_vm.userLang}})],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }