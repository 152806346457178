<template>
  <div>
    <v-btn
      class="ma-2"
      block
      outlined
      text
      @click="startCreatingNewOrganisation"
    >
      <copy item="addOrganisation" :lang="userLang"></copy>
    </v-btn>

    <p v-if="organisations === null" class="infos-label">Loading...</p>
    <p v-if="organisations && !organisations.length" class="infos-label">
      You don't have any organisations yet
    </p>
    <v-list two-line subheader>
      <v-list-item
      v-if="userOrganisation"
        @click="setOrgToEdit({ org: userOrganisation })"
      >
        <v-list-item-content>
          <v-list-item-title
            v-if="userOrganisation.name"
            v-text="userOrganisation.name.value[userLang]"
          ></v-list-item-title>
          <!-- <v-list-item-subtitle v-text="org.city"></v-list-item-subtitle> -->
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    // ...mapGetters('adminOrganisation', ['isOrgDeletionPending']),
    ...mapState('adminOrganisations', ['organisations']),
    ...mapState('authentication', ['userLang', 'userActiveOrganisation']),
    ...mapState('app', ['networkOnLine']),
    ...mapGetters('adminOrganisations', ['getOrganisationById']),
    userOrganisation() {
      if(this.organisations === null) return null;
      
      console.log(this.getOrganisationById(this.userActiveOrganisation.id))
      return this.getOrganisationById(this.userActiveOrganisation.id)
    }
  },
  methods: {
    ...mapActions('adminOrganisations', [
      'startCreatingNewOrganisation',
      'setOrgToEdit',
      'getOrganisations'
    ])
  },
  created() {
    this.getOrganisations()
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.infos-label {
  text-align: center;
}

.product-row {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 10px auto;
  justify-content: space-between;
}
</style>
