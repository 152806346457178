<template>
  <v-dialog v-model="sendingBooking" width="500">
    <v-card>
      <v-card-text>
        <svg
          style="margin:5rem;"
          viewBox="0 0 86 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="43" cy="43" r="43" fill="#FFFBA6" />
          <path
            d="M60.375 64.375H39.8906C38.7866 64.375 37.6395 63.8295 36.4234 63.2516C35.3539 62.7427 34.2499 62.2188 33.4219 62.2188H29.1094C28.5143 62.2188 28.0312 61.7357 28.0312 61.1406C28.0312 60.5455 28.5143 60.0625 29.1094 60.0625H33.4219C34.735 60.0625 36.0633 60.6943 37.3484 61.3045C38.295 61.753 39.2718 62.2188 39.8906 62.2188H60.375C61.5631 62.2188 62.5312 61.2528 62.5312 60.0625C62.5312 58.8722 61.5631 57.9062 60.375 57.9062C59.7799 57.9062 59.2969 57.4232 59.2969 56.8281C59.2969 56.233 59.7799 55.75 60.375 55.75H61.4531C62.6412 55.75 63.6094 54.784 63.6094 53.5938C63.6094 52.4035 62.6412 51.4375 61.4531 51.4375C60.858 51.4375 60.375 50.9545 60.375 50.3594C60.375 49.7642 60.858 49.2812 61.4531 49.2812H62.5312C63.7193 49.2812 64.6875 48.3153 64.6875 47.125C64.6875 45.9347 63.7193 44.9688 62.5312 44.9688H61.4531C60.858 44.9688 60.375 44.4858 60.375 43.8906C60.375 43.2955 60.858 42.8125 61.4531 42.8125C62.6412 42.8125 63.6094 41.8465 63.6094 40.6562C63.6094 39.466 62.6412 38.5 61.4531 38.5H40.9601C40.6281 38.5 40.3154 38.3469 40.1106 38.086C39.9057 37.8251 39.8346 37.4844 39.9143 37.161C39.923 37.1178 40.9601 32.9024 40.9601 27.8611C40.9601 22.2527 38.7198 21.4031 36.6541 21.2737V28.7969C36.6541 28.9629 36.6153 29.1289 36.5398 29.2799L32.0656 38.2262C31.7982 38.7566 31.1535 38.9765 30.6187 38.7092C30.0862 38.4418 29.8705 37.7949 30.1357 37.2623L34.5 28.5424V20.1719C34.5 19.5768 34.983 19.0938 35.5781 19.0938C37.8336 19.0938 43.1185 19.0937 43.1185 27.8589C43.1185 31.4792 42.6247 34.6382 42.2948 36.3438H61.4531C63.8315 36.3438 65.7656 38.2779 65.7656 40.6562C65.7656 41.7107 65.384 42.6788 64.7522 43.4292C66.005 44.186 66.8438 45.5574 66.8438 47.125C66.8438 48.6926 66.005 50.064 64.7522 50.8208C65.384 51.5712 65.7656 52.5393 65.7656 53.5938C65.7656 55.1613 64.9268 56.5327 63.6741 57.2896C64.3058 58.0399 64.6875 59.0081 64.6875 60.0625C64.6875 62.4408 62.7533 64.375 60.375 64.375Z"
            fill="#263238"
          />
          <path
            d="M29.2193 64.375H21.5625V62.2188H28.0312V42.8125H21.5625V40.6562H29.2193C29.8166 40.6562 30.1875 41.1026 30.1875 41.6956V63.2581C30.1875 63.8575 29.8166 64.375 29.2193 64.375Z"
            fill="#263238"
          />
          <path
            d="M3.34375 68.6875C2.74863 68.6875 2.26562 68.2045 2.26562 67.6094C2.26562 67.0142 2.74863 66.5312 3.34375 66.5312H21.5619V42.8125H3.34375C2.74863 42.8125 2.26562 42.3295 2.26562 41.7344C2.26562 41.1393 2.74863 40.6562 3.34375 40.6562H22.75C23.3473 40.6562 23.7182 41.1026 23.7182 41.6956V67.5706C23.7182 68.17 23.3473 68.6875 22.75 68.6875H3.34375Z"
            fill="#263238"
          />
          <path
            d="M62.5303 51.4375H51.749C49.3707 51.4375 47.4365 49.5033 47.4365 47.125C47.4365 44.7467 49.3707 42.8125 51.749 42.8125H62.5303C64.9086 42.8125 66.8428 44.7467 66.8428 47.125C66.8428 49.5033 64.9086 51.4375 62.5303 51.4375ZM51.749 44.9688C50.5609 44.9688 49.5928 45.9348 49.5928 47.125C49.5928 48.3152 50.5609 49.2812 51.749 49.2812H62.5303C63.7184 49.2812 64.6865 48.3152 64.6865 47.125C64.6865 45.9348 63.7184 44.9688 62.5303 44.9688H51.749Z"
            fill="#263238"
          />
          <path
            d="M60.374 64.375H53.9053C51.5269 64.375 49.5928 62.4408 49.5928 60.0625C49.5928 57.6842 51.5269 55.75 53.9053 55.75H60.374C62.7524 55.75 64.6865 57.6842 64.6865 60.0625C64.6865 62.4408 62.7524 64.375 60.374 64.375ZM53.9053 57.9062C52.7172 57.9062 51.749 58.8723 51.749 60.0625C51.749 61.2527 52.7172 62.2188 53.9053 62.2188H60.374C61.5621 62.2188 62.5303 61.2527 62.5303 60.0625C62.5303 58.8723 61.5621 57.9062 60.374 57.9062H53.9053Z"
            fill="#263238"
          />
          <path
            d="M61.4521 57.9062H52.8271C50.4488 57.9062 48.5146 55.9721 48.5146 53.5938C48.5146 51.2154 50.4488 49.2812 52.8271 49.2812H61.4521C63.8305 49.2812 65.7646 51.2154 65.7646 53.5938C65.7646 55.9721 63.8305 57.9062 61.4521 57.9062ZM52.8271 51.4375C51.6391 51.4375 50.6709 52.4035 50.6709 53.5938C50.6709 54.784 51.6391 55.75 52.8271 55.75H61.4521C62.6402 55.75 63.6084 54.784 63.6084 53.5938C63.6084 52.4035 62.6402 51.4375 61.4521 51.4375H52.8271Z"
            fill="#263238"
          />
          <path
            d="M61.4521 44.9688H52.8271C50.4488 44.9688 48.5146 43.0346 48.5146 40.6562C48.5146 38.2779 50.4488 36.3438 52.8271 36.3438H61.4521C63.8305 36.3438 65.7646 38.2779 65.7646 40.6562C65.7646 43.0346 63.8305 44.9688 61.4521 44.9688ZM52.8271 38.5C51.6391 38.5 50.6709 39.466 50.6709 40.6562C50.6709 41.8465 51.6391 42.8125 52.8271 42.8125H61.4521C62.6402 42.8125 63.6084 41.8465 63.6084 40.6562C63.6084 39.466 62.6402 38.5 61.4521 38.5H52.8271Z"
            fill="#263238"
          />
          <path
            d="M37.9662 53.7101C37.4768 53.7101 37.0326 53.3759 36.9183 52.8757C36.7825 52.2956 37.1426 51.7178 37.7226 51.5798C40.2238 50.9954 42.0416 48.8499 42.5871 45.842C43.1391 42.793 42.1925 39.6816 40.336 38.4417C39.84 38.1118 39.7063 37.4412 40.0362 36.9474C40.364 36.4515 41.0324 36.3178 41.5305 36.6477C44.1525 38.3964 45.429 42.2454 44.7088 46.2258C44.0059 50.107 41.578 52.8929 38.2142 53.6799C38.1301 53.7015 38.0482 53.7101 37.9662 53.7101Z"
            fill="#263238"
          />
          <path
            d="M43.4371 16.6335C43.7714 16.6335 44.0991 16.4804 44.3104 16.1893L46.8462 12.7005C47.1976 12.2175 47.0898 11.5447 46.609 11.1954C46.126 10.8461 45.4511 10.9496 45.1039 11.4326L42.5682 14.9214C42.2167 15.4044 42.3245 16.0772 42.8053 16.4265C42.9951 16.5666 43.215 16.6335 43.4371 16.6335Z"
            fill="#263238"
          />
          <path
            d="M45.9365 20.7411C46.0852 21.1983 46.5079 21.4872 46.9628 21.4872C47.0728 21.4872 47.1849 21.47 47.2949 21.4355L51.3961 20.1029C51.9632 19.9196 52.2737 19.3115 52.0882 18.7445C51.9028 18.1752 51.2926 17.8669 50.7298 18.0523L46.6286 19.3849C46.0637 19.566 45.7532 20.174 45.9365 20.7411Z"
            fill="#263238"
          />
          <path
            d="M29.5316 26.0714C29.3484 25.5021 28.7381 25.1938 28.1732 25.3792L24.072 26.7118C23.5049 26.895 23.1944 27.5031 23.3799 28.0702C23.5286 28.5273 23.9513 28.8163 24.4062 28.8163C24.5162 28.8163 24.6283 28.799 24.7383 28.7645L28.8395 27.432C29.4044 27.2465 29.7149 26.6385 29.5316 26.0714Z"
            fill="#263238"
          />
          <path
            d="M51.3961 26.7117L47.2949 25.3792C46.7278 25.1937 46.1219 25.5042 45.9365 26.0713C45.7532 26.6384 46.0637 27.2465 46.6286 27.4298L50.7298 28.7623C50.8398 28.799 50.9519 28.8141 51.0619 28.8141C51.5168 28.8141 51.9395 28.5252 52.0882 28.068C52.2737 27.5031 51.9632 26.895 51.3961 26.7117Z"
            fill="#263238"
          />
          <path
            d="M24.072 20.1007L28.1732 21.4333C28.2832 21.47 28.3953 21.485 28.5053 21.485C28.9602 21.485 29.3829 21.1961 29.5316 20.739C29.7149 20.1719 29.4044 19.5638 28.8395 19.3805L24.7383 18.048C24.1734 17.8647 23.5653 18.173 23.3799 18.7401C23.1944 19.3094 23.5049 19.9175 24.072 20.1007Z"
            fill="#263238"
          />
          <path
            d="M30.3661 11.4325C30.0168 10.9474 29.3419 10.846 28.861 11.1954C28.3802 11.5447 28.2724 12.2196 28.6238 12.7004L31.1596 16.1892C31.3709 16.4803 31.6986 16.6334 32.0329 16.6334C32.2528 16.6334 32.4749 16.5666 32.6646 16.4264C33.1455 16.0771 33.2533 15.4022 32.9018 14.9214L30.3661 11.4325Z"
            fill="#263238"
          />
          <path
            d="M37.7334 8.3125C37.1383 8.3125 36.6553 8.7955 36.6553 9.39062V13.7031C36.6553 14.2983 37.1383 14.7812 37.7334 14.7812C38.3285 14.7812 38.8115 14.2983 38.8115 13.7031V9.39062C38.8115 8.7955 38.3285 8.3125 37.7334 8.3125Z"
            fill="#263238"
          />
        </svg>

        <p>
          <copy item="BookingDoneSendMail" :lang="userLang"></copy>
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn outlined text @click="goToDashboard()">
          <copy item="backToDashboard" :lang="userLang"></copy>
          
        </v-btn>
        <div class="flex-grow-1"></div>
            <!-- <v-btn outlined text @click="resetBookingForced">
              Nieuwe booking
            </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState('authentication', ['userLang']),
    ...mapState('adminBookings', ['sendingBooking'])
  },
  methods: {
    ...mapActions('adminBookings', ['goTo']),
    ...mapMutations('adminBookings', ['setSendingBooking']),
    goToDashboard(){
      this.setSendingBooking(false);
      this.goTo('dashboard');
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/admin.scss';

.numberAnimation {
  animation-duration: 0.2s;
}
.v-expansion-panel::before {
  box-shadow: none;
}

.product__card {
  .product {
    &__card {
    }
    &__wrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  .info {
    &__wrapper {
      display: flex;
      flex-flow: column;
      width: 100%;
    }

    &__label {
      text-transform: capitalize;
      width: 8rem;
      display: inline-block;
    }
    &__name {
      font-weight: 800;
      font-size: 1.2rem;

      line-height: 2rem;
    }
    &__desc {
    }
  }

  .more {
    &__button {
      color: rgba(0, 0, 0, 0.54);
    }
    color: rgba(0, 0, 0, 0.54);
    &__label {
      line-height: 2rem;
      text-transform: uppercase;
      font-size: 0.8rem;
      color: rgba(0, 0, 0, 0.54);
    }
    &__value {
      padding-left: 0.4rem;
      color: rgba(0, 0, 0, 0.54);
    }
  }
  .action {
    &__wrapper {
      height: 6rem;
      font-size: 3rem;
      display: flex;
      padding-top: 0.2rem;
      flex-flow: column;
      justify-content: space-between;
      & i {
        font-size: 3rem;
      }
    }
  }
  .amount {
    &__wrapper {
      margin-right: 1rem;
      width: 5rem;
      margin-top: 1rem;
      position: relative;
    }

    &__labels {
      height: 8rem;
      position: absolute;
      font-size: 0.6rem;
      width: 7.9rem;
      right: 0.6rem;
      top: -1.5rem;
      z-index: 2;
      &--one {
        top: 0;
        position: absolute;
        left: 0;
      }
      &--two {
        bottom: 0;
        position: absolute;
        right: 0;
      }
    }
    &__numbers {
      right: 2rem;
      top: 0;
      position: relative;
      width: 5.4rem;
      height: 100%;
      display: flex;
      flex-flow: column;
      font-size: 2rem;
      font-weight: 400;
      justify-content: space-between;
      &--one {
      }
      &--two {
        text-align: right;
      }
    }
    &__diagonal {
      top: -0.4rem;
      right: -0.3rem;
      width: 10rem;
      height: 6rem;

      position: absolute;
      &:after {
        content: '';
        position: absolute;
        border-top: 2px solid;
        width: 100%;
        transform: rotate(-45deg);
        top: 3rem;
        left: 0rem;
      }
    }
  }
}
</style>
