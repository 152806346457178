<template>
  <router-view></router-view>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  methods: {
    nav(path) {
      router.push(path)
    },
    ...mapActions('adminOrganisations', ['getOrganisations'])
  },
  head: function() {
    return {
      title: {
        inner: 'Admin - Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} admin home page`,
          id: 'desc'
        }
      ]
    }
  },
  created() {
    this.getOrganisations()
  },
  computed: {
    ...mapState('app', ['appTitle']),
    ...mapState('authentication', ['userLang'])
  }
}
</script>
