<template>
  <v-row align="center">
    <v-col cols="12" sm="12" md="12">
      <copy item="addSpecNoSave" :lang="userLang"></copy>:
      <admin-products-spec-add-dialog
        v-for="(s, specKey) in specDefaults"
        :key="'spec-' + specKey"
        :spec="s"
      ></admin-products-spec-add-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import AdminProductsSpecAddDialog from '@/components/Admin-ProductsSpecAddDialog'

export default {
  components: { AdminProductsSpecAddDialog },
  computed: {
    ...mapState('translations', ['languages']),
    ...mapState('adminProducts', ['specDefaults']),
    ...mapState('authentication', ['userLang'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/admin.scss';
</style>
