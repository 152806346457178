<template>
  <div
    v-if="stock.length != 0 && productInfo != undefined"
    class="selectedProduct__wrapper"
  >
    <span>{{ productInfo.default.name.value[userLang] }}:</span>
    <span>{{ stock.length }}</span>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
// import MainBookingStockPerProduct from '@/components/Main-BookingStockPerProduct'

export default {
  props: {
    product: String,
    stock: Array
  },
  computed: {
    ...mapState('authentication', ['userLang']),
    ...mapGetters('adminBookings', ['getProductById']),
    productInfo() {
      return this.getProductById(this.product)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/admin.scss';
.selectedProduct {
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
}
</style>
