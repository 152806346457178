var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.edit)?_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"outlined":""},on:{"click":_vm.resetUserOrgToEdit}},on),[_c('copy',{attrs:{"item":"addOrganisation","lang":_vm.userLang}})],1):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_c('copy',{attrs:{"item":"addOrganisation","lang":_vm.userLang}})],1),_c('v-card-text',[_c('v-form',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('v-select',{attrs:{"disabled":_vm.edit,"items":_vm.organisationsFilteredForUser,"label":_vm.copy('organisation', _vm.userLang),"item-value":"id","outlined":""},on:{"input":function($event){return _vm.editSelectedUserOrgToEdit({
                    value: $event,
                    fieldName: 'orgId'
                  })}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
                  var id = ref.id;
return [_vm._v("\n                  "+_vm._s(item.name.value[_vm.userLang])+"\n                ")]}},{key:"item",fn:function(ref){
                  var item = ref.item;
                  var id = ref.id;
return [_vm._v("\n                  "+_vm._s(item.name.value[_vm.userLang])+"\n                ")]}}])})],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('v-select',{attrs:{"value":_vm.selectedUserOrgToEdit.role,"item-text":"name","items":_vm.userRoles,"label":_vm.copy('role', _vm.userLang),"item-value":"value","outlined":""},on:{"input":function($event){return _vm.editSelectedUserOrgToEdit({
                    value: $event,
                    fieldName: 'role'
                  })}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
                  var id = ref.id;
return [_vm._v("\n                  "+_vm._s(item.name[_vm.userLang])+"\n                ")]}},{key:"item",fn:function(ref){
                  var item = ref.item;
                  var id = ref.id;
return [_vm._v("\n                  "+_vm._s(item.name[_vm.userLang])+"\n                ")]}}])})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"error","outlined":""},on:{"click":_vm.cancelAddingOrganisation}},[_c('copy',{attrs:{"item":"cancel","lang":_vm.userLang}})],1),_c('div',{staticClass:"flex-grow-1"}),(_vm.edit)?[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","text":""},on:{"click":_vm.updateAndClose}},[_c('copy',{attrs:{"item":"saveUser","lang":_vm.userLang}})],1)]:[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","text":""},on:{"click":_vm.addOrganisationAndClose}},[_c('copy',{attrs:{"item":"add","lang":_vm.userLang}})],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }