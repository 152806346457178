<template>
  <v-dialog v-model="dialog" persistent>
    <template v-slot:activator="{ on }">
      <v-btn class="ma-2" outlined v-on="on" @click="setNewSpecDefaults(spec)">
        {{ spec.typeLabel[userLang] }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        <span class="admin-spec__title"
          ><copy item="addSpecsaveProduct" :lang="userLang"></copy>
        </span>
        <span class="admin-spec__title--specName">
          {{ spec.typeLabel[userLang] }}
        </span>
      </v-card-title>

      <v-card-text v-if="newSpec != null">
        <v-form>
          <v-container>
            <v-row align="start">
              <v-col cols="12" sm="12" md="6">
                <!-- LABEL OF THE SPEC -->

                <p class="admin-form__label">
                  <copy item="specLabel" :lang="userLang"></copy>
                </p>
                <v-text-field
                  v-for="(l, lang) in languages"
                  :key="'specLabelLang-' + lang"
                  :label="l.name"
                  :placeholder="copy('specLabel', userLang)"
                  :value="newSpec.label[lang]"
                  outlined
                  @input="
                    setNewSpecField({
                      value: $event,
                      fieldName: 'label',
                      lang: lang
                    })
                  "
                ></v-text-field>
              </v-col>

              <!-- VALUE OF THE SPEC. This is type dependend -->
              <v-col cols="12" sm="12" md="6">
                <template v-if="newSpec.i18n">
                  <p class="admin-form__label">
                    <copy item="specValue" :lang="userLang"></copy>
                  </p>
                  <v-text-field
                    v-for="(l, lang) in languages"
                    :key="'specValueLang-' + lang"
                    :label="l.name"
                    :placeholder="copy('specValue', userLang)"
                    :value="newSpec.value[lang]"
                    outlined
                    @input="
                      setNewSpecField({
                        value: $event,
                        fieldName: 'value',
                        lang: lang
                      })
                    "
                  ></v-text-field>
                </template>
                <template v-else>
                  <p class="admin-form__label">
                    <copy item="specValue" :lang="userLang"></copy>
                  </p>
                  <template v-if="newSpec.type == 'string'">
                    <v-text-field
                      :placeholder="copy('specValue', userLang)"
                      :value="newSpec.value"
                      outlined
                      @input="
                        setNewSpecField({
                          value: $event,
                          fieldName: 'value',
                          lang: null
                        })
                      "
                    ></v-text-field>
                  </template>

                  <template v-if="newSpec.type == 'number'">
                    <v-text-field
                      :placeholder="copy('specValue', userLang)"
                      :value="newSpec.value[0]"
                      outlined
                      @input="
                        setNewSpecField({
                          value: $event,
                          fieldName: 'value',
                          lang: null
                        })
                      "
                    ></v-text-field>
                  </template>

                  <template v-if="newSpec.type == 'range'">
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          :placeholder="copy('specValue', userLang) + ' 1'"
                          :value="newSpec.value[0][0]"
                          outlined
                          @input="
                            setNewSpecField({
                              value: [$event, newSpec.value[0][1]],
                              fieldName: 'value',
                              lang: null
                            })
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          :placeholder="copy('specValue', userLang) + ' 2'"
                          :value="newSpec.value[0][1]"
                          outlined
                          @input="
                            setNewSpecField({
                              value: [newSpec.value[0][0], $event],
                              fieldName: 'value',
                              lang: null
                            })
                          "
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </template>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          class="ma-2"
          color="error"
          outlined
          @click="cancelSpecCreationAndClose"
        >
          <copy item="cancel" :lang="userLang"></copy>
        </v-btn>
        <div class="flex-grow-1"></div>

        <v-btn class="ma-2" outlined text @click="addSpecAndClose">
          <copy item="add" :lang="userLang"></copy>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'

export default {
  props: {
    spec: Object
  },
  data() {
    return {
      dialog: false
    }
  },

  // TODO: Clean up whats not necessary
  computed: {
    ...mapState('translations', ['languages']),
    ...mapState('adminProducts', ['newSpec']),
    ...mapState('authentication', ['userLang']),
    ...mapGetters('translations', ['copy'])
  },
  methods: {
    ...mapMutations('adminProducts', ['setNewSpecField']),
    ...mapActions('adminProducts', [
      'setNewSpecDefaults',
      'addSpec',
      'resetSpec'
    ]),
    cancelSpecCreationAndClose() {
      this.dialog = false
      this.resetSpec()
    },
    addSpecAndClose() {
      this.dialog = false
      this.addSpec()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/admin.scss';
</style>
