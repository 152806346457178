<template>
  <div>
    
    <p v-if="users === null" class="infos-label">
      <copy item="loading" :lang="userLang"></copy>
    </p>
    <p v-if="users && !users.length" class="infos-label">
      <copy item="noUsersInList" :lang="userLang"></copy>
    </p>
    <div v-if="users == null">
      <copy item="noUsersInList" :lang="userLang"></copy>
    </div>
    <v-data-table
      v-else
      :headers="headers"
      :items="usersFiltered"
      :sort-by="['name']"
      :sort-desc="[false]"
      class="elevation-1"
      multi-sort
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>
            <copy item="users" :lang="userLang"></copy>
          </v-toolbar-title>
          <div class="flex-grow-1"></div>
          <admin-users-detail :edit="false"></admin-users-detail>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <admin-users-detail :edit="true" :user="item"></admin-users-detail>
        <v-icon small @click="deletePrompt(item)">
          mdi-delete
        </v-icon>
        <v-icon small @click="sendMagicLink({ email: item.email.value })">
          mdi-email
        </v-icon>
      </template>
      <template v-slot:item.email="{ item }">
        
        <span v-if="item.email">
          {{ item.email.value }}
        </span>
      </template>
       <template v-slot:item.firstName="{ item }">
        <span v-if="item.firstName">
          {{ item.firstName.value }} 
        </span>
      </template>
      <template v-slot:item.lastName="{ item }">
        <span v-if="item.lastName">
          {{ item.lastName.value }}
        </span>
      </template>
      <template v-slot:item.school="{ item }">
        <span v-if="item.school">
          {{ item.school.value }}
        </span>
      </template>
      <template v-slot:item.language="{ item }">
        <span v-if="item.lang">
          {{ item.lang.value.locale }}
        </span>
      </template>
       <template v-slot:item.role="{ item }">
        <span v-if="item.organisations">
          <div v-for="i in  item.organisations">
            {{getUserRoleById(i).name[userLang] }}
          </div>
        </span>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import AdminUsersDetail from '@/components/Admin-UsersDetail'

export default {
  components: { AdminUsersDetail },
  data() {
    return {
      headers: [
        { text: 'First name', value: 'firstName.value', sortable: true},
        { text: 'Last name', value: 'lastName.value', sortable: true},
        { text: 'School', value: 'school.value', sortable: true},
        { text: 'Language', value: 'lang.value.locale', sortable: true},
        { text: 'Role', value: 'role', sortable: false},
        { text: 'Email', value: 'email.value', sortable: true},
        { text: 'Id', value: 'id', sortable: true},
        { text: 'Actions', value: 'action', sortable: false, align: 'center' }
      ]
    }
  },
  computed: {
    ...mapGetters('adminUsers', ['isUserDeletionPending', 'getUserRoleById']),
    ...mapState('adminUsers', ['users', 'userSuccess', 'userError']),
    ...mapState('admin', ['userRoles']),
    ...mapState('translations', ['languages']),
    ...mapState('authentication', ['userLang', 'userActiveOrganisation']),
    ...mapState('app', ['networkOnLine']),
    usersFiltered() {
      return this.users.filter(user => {
        return !isNaN(user.organisations[this.userActiveOrganisation.id])
      })
    }
  },
  created() {
    this.getUsers()
  },
  methods: {
    ...mapActions('adminUsers', ['getUsers', 'deleteUser']),
    ...mapActions('authentication', ['sendMagicLink']),
    deletePrompt(item) {
      // TODO: translate
      confirm('delete user') && this.deleteUser({ userId: item.id })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
