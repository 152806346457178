<template>
  <v-card  v-if="productInfo" class="mx-auto product__card">
    
    <v-card-text>
      <div class="product__wrapper">
        
        <div class="info__wrapper">

          <div class="info__name">
            <!-- <span class="info__label">
              {{ productInfo.default.name.label[userLang] }}:
            </span> -->
            <span class="info__value" :class="{ 'animated pulse numberAnimation': animatedNumber, 'amount__numbers--active': selectedStockTotal > 0,  }">
              {{ productInfo.default.name.value[userLang] }}
            </span>
          </div>
          <div class="info__descr">
            <!-- <span class="info__label">
              {{ productInfo.default.descr.label[userLang] }}:
            </span> -->
            <div class="info__value">
              {{ productInfo.default.descr.value[userLang] }}
            </div>
          </div>
        </div>

        <div class="amount__wrapper">
          <div class="amount__labels">
            <div class="amount__labels--one">
              <copy item="geselecteerd" :lang="userLang"></copy>
            </div>
            <div class="amount__labels--two">
              <copy item="available" :lang="userLang"></copy>
            </div>
          </div>

          <div class="amount__diagonal"></div>
          <div class="amount__numbers">
            <div
              class="amount__numbers--one"
              :class="{ 'animated pulse numberAnimation': animatedNumber, 'amount__numbers--active': selectedStockTotal > 0,  }"
            >
              {{ selectedStockTotal }}
            </div>
            <div class="amount__numbers--two">
              {{ stockTotal }}
            </div>
          </div>
        </div>
        <div class="action__wrapper">
          <v-btn
            icon
            :disabled="stockTotal == 0"
            @click.stop
            @click.exact="addOneToSelection({ productId: productId })"
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-btn>
          <v-btn
            icon
            :disabled="selectedStockTotal == 0"
            @click.stop
            @click.exact="removeOneFromSelection({ productId: productId })"
          >
            <v-icon>mdi-minus-circle-outline</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card-text>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="more__button">
            <copy item="moreInfo" :lang="userLang"></copy>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- tre -->
          <!-- <span class="more__label">database Id:</span>
          <span class="more__value">{{ productInfo.id }}</span> -->
          
          <div v-for="item in productInfo.specs" :key="item.id">
            <div v-if="item.type === 'range'">
              <span class="more__label">{{ item.label[userLang] }}:</span>
              <span class="more__value">
                {{ item.value[0] }} -
                {{ item.value[1] }}
              </span>
            </div>

            <div v-else-if="item.type === 'number'">
              <span class="more__label">{{ item.label[userLang] }}:</span>
              <span class="more__value">{{ item.value }}</span>
            </div>
            <div v-else-if="item.type === 'string'">
              <span class="more__label">{{ item.label[userLang] }}:</span>
              <span class="more__value">{{ item.value[userLang] }}</span>
            </div>
          </div>
          <template v-if="productInfo.default.image">
            <div class="info__image" v-if="productInfo.default.image.value !== null">
            <v-img :src="productInfo.default.image.value" ></v-img>
            </div>
          </template> 
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
// import AdminProductsSpecAddDialog from '@/components/Admin-ProductsSpecAddDialog'

export default {
  // components: { AdminProductsSpecAddDialog },
  props: {
    productId: String,
    stockTotal: Number,
    selectedStockTotal: Number
  },
  data() {
    return {
      animating: false
    }
  },
  computed: {
    ...mapGetters('adminBookings', ['getProductById']),
    ...mapState('adminProducts', ['specDefaults']),
    ...mapState('authentication', ['userLang']),
    ...mapState('adminBookings', ['bookingDateRange']),
    animatedNumber: function() {
      return this.animating
    },
    productInfo() {
      return this.getProductById(this.productId)
    }
  },
  methods: {
    // ...mapMutations('adminBookings', ['lookUpDateRange'])
    ...mapActions('adminBookings', [
      'checkDateForBooking',
      'addOneToSelection',
      'removeOneFromSelection'
    ])
  },
  watch: {
    selectedStockTotal: function() {
      this.animating = true
      setTimeout(() => {
        this.animating = false
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/admin.scss';

.numberAnimation {
  animation-duration: 0.2s;
}
.v-expansion-panel::before {
  box-shadow: none;
}

.product__card {
  .product {
    &__card {
    }
    &__wrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  .info {
    &__wrapper {
      display: flex;
      flex-flow: column;
      width: 100%;
    }

    &__label {
      text-transform: capitalize;
      width: 8rem;
      display: inline-block;
    }
    &__name {
      font-weight: 800;
      font-size: 1.2rem;
      line-height: 2rem;
    }
    &__image {
      
    }
    &__desc {
    }
  }

  .more {
    &__button {
      color: rgba(0, 0, 0, 0.54);
    }
    color: rgba(0, 0, 0, 0.54);
    &__label {
      line-height: 2rem;
      text-transform: uppercase;
      font-size: 0.8rem;
      color: rgba(0, 0, 0, 0.54);
    }
    &__value {
      padding-left: 0.4rem;
      color: rgba(0, 0, 0, 0.54);
    }
  }
  .action {
    &__wrapper {
      height: 6rem;
      font-size: 3rem;
      display: flex;
      padding-top: 0.2rem;
      flex-flow: column;
      justify-content: space-between;
      & i {
        font-size: 3rem;
      }
    }
  }
  .amount {
    &__wrapper {
      margin-right: 1rem;
      width: 5rem;
      margin-top: 1rem;
      position: relative;
    }

    &__labels {
      height: 8rem;
      position: absolute;
      font-size: 0.6rem;
      width: 7.9rem;
      right: 0.6rem;
      top: -1.5rem;
      z-index: 2;
      &--one {
        top: 0;
        position: absolute;
        left: 0;
      }
      &--two {
        bottom: 0;
        position: absolute;
        right: 0;
      }
    }
    &__numbers {
      right: 2rem;
      top: 0;
      position: relative;
      width: 5.4rem;
      height: 100%;
      display: flex;
      flex-flow: column;
      font-size: 2rem;
      font-weight: 400;
      justify-content: space-between;
      &--active {
        color: rgb(238, 42, 123);
      }
      &--one {
      }
      &--two {
        text-align: right;
      }
    }
    &__diagonal {
      top: -0.4rem;
      right: -0.3rem;
      width: 10rem;
      height: 6rem;

      position: absolute;
      &:after {
        content: '';
        position: absolute;
        border-top: 2px solid;
        width: 100%;
        transform: rotate(-45deg);
        top: 3rem;
        left: 0rem;
      }
    }
  }
}
</style>
