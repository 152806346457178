<template>
  <div>
    <h3>Locaties</h3>
    <template
      v-if="(orgLocations && !orgLocations.length) || orgLocations === null"
      class="infos-label"
    >
      <copy item="noLocations" :lang="userLang"></copy>
    </template>

    <v-data-table
      v-if="orgLocations && orgLocations.length"
      :headers="headers"
      :items="orgLocations"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item.action="{ item }">
        <admin-organisations-locations-detail
          :edit="true"
          :location="item"
        ></admin-organisations-locations-detail>
        <v-icon small @click="deletePrompt(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:item.name="{ item }">
        <span v-if="getOrganisationById(item.orgId)">
          {{ getOrganisationById(item.orgId).name.value[userLang] }}
        </span>
        <span v-else>
          {{ item.orgId }}
        </span>
      </template>
    </v-data-table>

    <admin-organisations-locations-detail
      :edit="false"
    ></admin-organisations-locations-detail>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import AdminOrganisationsLocationsDetail from '@/components/Admin-OrganisationsLocationsDetail'

export default {
  components: {
    AdminOrganisationsLocationsDetail
  },
  data() {
    return {
      dialog: false,
      // TODO: Generate these headers based on the default fields?
      // TODO: Fix the one language thing here. Should use slot for header
      headers: [
        { text: 'Name', value: 'name.value[0]' },
        { text: 'Id', value: 'id' },
        { text: 'Actions', value: 'action', sortable: false, align: 'center' }
      ]
    }
  },
  computed: {
    ...mapState('translations', ['languages']),
    ...mapState('authentication', ['userLang']),
    ...mapState('app', ['networkOnLine']),
    ...mapState('adminOrganisations', [
      'locationDefaults',
      'orgLocations',
      'orgLocations'
    ])
    // ...mapGetters('adminOrganisations', ['getLocationById'])
  },
  methods: {
    ...mapMutations('adminOrganisations', [
      'setLocationToCreate',
      'setLocationToCreateToDefaults'
    ]),
    ...mapActions('adminOrganisations', [
      'createLocation',
      'getLocations',
      'deleteLocation'
    ]),
    cancelAddingLocation() {
      this.dialog = false
    },
    createLocationAndClose() {
      this.dialog = false
      this.createLocation()
    },
    deletePrompt(item) {
      console.log(item.id)
      confirm('Delete Location?') &&
        this.deleteLocation({ locationId: item.id })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
