<template>
  <v-container fluid>
    <v-row align="start" justify="start" class="grey lighten-5">
      <v-col cols="12" sm="12" md="12">
        <main-booking-module></main-booking-module>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import mainBookingModule from '@/components/Main-BookingModule'

export default {
  components: {
    mainBookingModule
  },
  computed: {
    ...mapState('app', ['networkOnLine']),
    ...mapState('adminOrganisations', ['orgFunction'])
  }
}
</script>
