<template>
  <v-container fluid>
    <v-row align="start" justify="start" class="grey lighten-5">
      <v-col cols="12" sm="12" md="4">
        <admin-organisations-list></admin-organisations-list>
      </v-col>

      
      <v-col v-if="orgFunction != null" cols="12" sm="12" md="8">
        <admin-organisations-detail
          v-if="networkOnLine"
        ></admin-organisations-detail>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import AdminOrganisationsList from '@/components/Admin-OrganisationsList'
import AdminOrganisationsDetail from '@/components/Admin-OrganisationsDetail'

export default {
  components: { AdminOrganisationsList, AdminOrganisationsDetail },
  computed: {
    ...mapState('app', ['networkOnLine']),
    ...mapState('adminOrganisations', ['orgFunction'])
  }
}
</script>
