<template>
  <v-container class="grey lighten-5" >
    <v-content>
      <v-container class="grey lighten-5" >
        <v-row justify="start" align="center">
          <v-col>
            <!--  Francais -->
            <v-expansion-panels v-if="userLang === 1">
              <v-expansion-panel
              >
                <v-expansion-panel-header><span class="title">Objectifs de la Vélotheek</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>La Vélotheek a pour but de prêter gratuitement des vélos aux écoles de Saint-Gilles, pour les aider à mettre en place des activités d’éducation au vélo. Les professeurs d’éducation physique sont particulièrement visés pour qu’ils puissent intégrer un apprentissage au vélo dans leur cours d’éducation physique. Mais tout professeur peut faire appel à la Vélotheek pour obtenir des vélos. </p>
                  <p>Le projet s’inscrit dans un programme plus large d’éducation au vélo à Saint-Gilles qui vise à apprendre aux élèves de primaire à se déplacer à vélo en ville.</p>

                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel
              >
                <v-expansion-panel-header><span class="title">Accompagnement Pro Velo, autonomie:</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>Il n’est pas toujours facile de donner une formation de vélo ou effectuer une sortie à vélo si l’on n’en a jamais fait. Pour vous aider à vous lancer,  vous pourrez solliciter Pro Velo qui pourra vous conseiller, vous partager son savoir-faire dans le domaine de l’éducation au vélo. Si vous désirez faire appel aux services de Pro Velo, veuillez cependant les solliciter le plus à l’avance possible car leur agenda se remplit assez rapidement. Une 50aine de jours d’accompagnement par Pro Velo seront disponibles ces trois prochaines années pour les différentes écoles de St-Gilles.</p>
                  <p>L’objectif à plus long terme est que vous puissiez donner des formations vélos en autonomie (sans Pro Velo).</p>

                </v-expansion-panel-content>
              </v-expansion-panel>


              <v-expansion-panel
              >
                <v-expansion-panel-header><span class="title">Fonctionnement général de la Vélotheek</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                    
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Prêt de vélo à la journée</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Le jour de la réservation, vous vous rendrez avec votre classe à la Vélotheek de votre choix pour prendre les vélos, vous effectuerez votre formation ou sortie à vélo et ensuite remettrez les vélos à la Vélotheek.</p>

                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Où faire sa formation vélo?</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          Vous pouvez effectuer votre formation dans la cour de récréation de votre école ou sur l’espace publique (place Morichar, square Jacques Franck, ...). Vous pouvez aussi effectuer un tour à vélo.
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Amener autant que possible le matériel des élèves</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          Il est important de systématiquement demander aux élèves d’amener leurs propres vélos, casques et chasubles. La vocation de la Vélotheek est de fournir des vélos aux élèves n’en ayant pas ou ne sachant pas l’amener à l’école.
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Rangement des vélos dans les Vélotheek</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Veuillez remettre les vélos après utilisation, rangés sur leurs pieds et par code couleur, de manière compacte.</p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Prendre soin du matériel</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          On vous demande de bien prendre soin du matériel. Tous les vélos sont munis de pied et ne doivent pas être couchés sur le sol. On demande de ne pas laisser les élèves faire de dérapage afin de ne pas accélérer l’usure des pneus et de ne pas les laisser jouer librement ce qui entraîne la plupart du temps des collisions entre vélos et la dégradation de ceux-ci. Dans l’espace public, il s’agira de faire attention à ne pas se faire voler des vélos ou autre matériel.
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">En cas de vélo endommagé</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          Il est cependant normal (surtout avec les débutants) d’avoir des chutes qui peuvent endommager les vélos (pneus crevés, freins cassés, ...). Il est important de nous le signaler directement pour que nous puissions au plus vite réparer les vélos concernés. Pour se faire, vous recevrez un Email après chaque emprunt de vélos auquel vous pourrez répondre et indiquer le cas échéant le numéro du vélo défectueux et le problème décelé. Quant au vélo cassé, mettez-le dans la zone « vélos défectueux » afin que les prochains utilisateurs ne le prennent pas.
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>


                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Matériel disponible dans les Vélotheek</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          Vous trouverez sur la plateforme de réservation les vélos disponibles dans chaque Vélotheek.
                        </p>
                        <p>
                          Pour votre facilité, vous trouverez sur chaque guidon de vélo un code couleur qui correspond à la taille du vélo:
                        </p>
                        <p>
                          <ul>
                            <li>Draisiennes: Pas de code de couleur (seulement à l’école Peter Pan)</li>
                            <li>Vélos 16 pouces: Code couleur rouge (seulement à l’école Peter Pan)</li>
                            <li>Vélos 20 pouces: Code couleur bleu</li>
                            <li>Vélos 24 pouces: Code couleur jaune</li>
                            <li>Vélos 26 pouces: Code couleur orange</li>
                            <li>Vélos Adultes: code couleur blanc</li>
                          </ul>
                        </p>
                        <p>
                          Outre les vélos, des casques et des chasubles sont aussi disponibles.
                        </p>
                        <p>
                          Du petit matériel de réparation est aussi à votre disposition: Une pompe à vélo et un jeu de clefs.
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>


                  
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>


              <v-expansion-panel>
                <v-expansion-panel-header><span class="title">Accès à la Vélotheek 4 saisons</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Horaires d’accès</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Vous pouvez prendre ou remettre des vélos pendant les horaires suivants:
                        <ul>
                          <li>de 8h30 à 12h30 et de 14h à 15h45 le lundi, mardi, jeudi et vendredi</li>
                          <li>de 8h30 à 11h le mercredi</li>
                        </ul>
                        <p>Afin de déranger le moins possible l’école 4 saisons, veillez à ne pas venir changer de vélos en cours de formation et à bien respecter ces horaires.</p> 
                        <p>Si une sortie à vélo nécessiterait de revenir plus tard, veuillez nous contacter préalablement afin que l’on voit si c’est possible pour l’école.</p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Procédure d’accès</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Le jour de votre réservation, vous devrez sonner au secrétariat de l’école 4 saisons (10 place Bethléem 1060 St Gilles). Veuillez décliner votre identité et le fait que vous venez pour la Vélotheek. Vous irez chercher les clefs de la Vélotheek au secrétariat de l’école, ressortirez de l’école pour vous diriger un peu plus bas sur la rue du Danemark. La porte donnant accès à la Vélotheek se trouve sur la rue du Danemark deux portes plus bas que l’entrée de l’école. On peut lire au dessus de celle-ci: “Cité des jeunes”. Les vélos sont stockés dans un local sur la gauche environ 20 mètres plus loin dans le couloir. Un panneau Vélotheek sur la porte indique que vous êtes bien au bon endroit. L'interrupteur de la lumière du local se situe sur la gauche 2 m après la porte. N’oubliez pas en sortant de bien éteindre la lumière du local et de fermer à clefs la porte du local ainsi que la porte donnant sur la rue. Vous remettrez ensuite les clefs au secrétariat avant de faire votre formation.  </p>
                        <p>Pour remettre les vélos, vous sonnerez à nouveau au secrétariat de l’école pour chercher les clefs qui vous permettront de remettre les vélos. Une fois les vélos et le matériel remis, n’oubliez pas de bien éteindre la lumière du local et de fermer à clefs la porte du local ainsi que la porte donnant sur la rue. Vous remettrez ensuite les clefs au secrétariat. </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>


              <v-expansion-panel>
                <v-expansion-panel-header><span class="title">Accès à la Vélotheek Peter Pan</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Horaires d’accès</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Vous pouvez prendre ou remettre des vélos pendant les horaires suivants:
                        <ul>
                          <li>de 9h à 12h et de 14h à 15h le lundi, mardi, jeudi et vendredi</li>
                          <li>de 9h à 11h le mercredi </li>
                        </ul>
                        <p>Afin de déranger le moins possible l’école Peter Pan, veillez à ne pas venir changer de vélos en cours de formation et à bien respecter ces horaires. </p> 
                        <p>Si une sortie à vélo nécessiterait de revenir plus tard, veuillez nous contacter préalablement afin que l’on voit si c’est possible pour l’école. </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Procédure d’accès</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Le jour de la réservation, vous devrez sonner au secrétariat de l’école Peter Pan (13 rue de la Rhétorique 1060 St Gilles). Veuillez décliner votre identité et le fait que vous venez pour la Vélotheek. La porte vous sera ouverte. Les clefs de la Vélotheek se trouvent dans une boîte à clef à votre gauche. Le code d’accès est le 1060.  Merci de bien faire attention de ne pas divulguer le code ainsi que de brouiller celui-ci après ouverture. Les vélos sont stockés dans un local dont l’accès se fait deux portes plus à droite que l’entrée principale de l’école Peter Pan derrière une porte métallique blanche. Les vélos se trouvent au fond du couloir. Une fois que vous aurez pris les vélos que vous désirez, vous sortirez sur le trottoir en n’oubliant pas d’éteindre la lumière et de fermer la porte à clef derrière vous. Vous garderez les clefs pendant votre formation.</p>
                        <p>Une fois votre formation terminée,  vous ouvrez la Vélotheek, remettez les vélos et le matériel à sa place. En sortant n’oubliez pas d’éteindre la lumière et de fermez à clef la porte. Vous sonnerez ensuite à nouveau à la sonnette du secrétariat de l’école Peter pan pour remettre la clef dans la boîte à clef. N’oubliez pas de brouiller le code après avoir remis les clefs.</p>

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header><span class="title">Fonctionnement du site de réservation</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>Une fois que vous êtes logué sur le site de réservation, vous pouvez cliquer sur “Nouvelle réservation” ou “Mes réservations” situé sur le haut de la page d'accueil.</p>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Nouvelle réservation</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Une nouvelle réservation se fait en 4 étapes</p>
                        <ol>
                          <li><span class="font-weight-bold">Choisir sa Vélotheek:</span> Vous devrez choisir si vous allez emprunter des vélos à l’école Peter pan ou à l’école 4 saisons. </li>
                          <li><span class="font-weight-bold">Choisir sa date:</span> Cliquez sur la date qui vous convient. Les dates disponibles sont indiqués en vert sur l’agenda:
                            <ul>
                              <li>rond vert complet pour disponible toute la journée</li>
                              <li>demi rond vert gauche pour disponible la matinée</li>
                              <li>demi rond vert droit pour disponible l’après midi</li>
                              <li>date grisée pour non disponible</li>
                            </ul>
                          </li>
                          <li><span class="font-weight-bold">Choisir son horaire:</span> Cliquez sur l’horaire qui vous convient (matin, après-midi ou journée). Si un horaire n’est pas disponible, il sera barré.</li>
                          <li><span class="font-weight-bold">Choisir ses vélos:</span> Vous devez sélectionner les vélos que vous voulez réserver. En cliquant sur infos, vous aurez les informations utiles pour choisir les vélos en fonction des âges ou des tailles des enfants. Malgré tout il n’est pas toujours facile de prévoir le bon nombre de vélos de bonne taille. Si votre sélection de vélos n’était pas totalement juste, ne vous inquiétez pas, vous aurez accès pour l’instant à l’ensemble des vélos de la Vélotheek.</li>
                          <li><span class="font-weight-bold">Réserver:</span> Une fois que vous avez sélectionné tous les vélos dont vous avez besoin, cliquez sur réserver.</li>

                        </ol>
                        <p>Afin de déranger le moins possible l’école Peter Pan, veillez à ne pas venir changer de vélos en cours de formation et à bien respecter ces horaires. </p> 
                        <p>Si une sortie à vélo nécessiterait de revenir plus tard, veuillez nous contacter préalablement afin que l’on voit si c’est possible pour l’école. </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Vos réservations</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Sur la page “ Vos réservations ”, vous pouvez voir l’ensemble de vos réservations. Le cas échéant, vous pourrez annuler une formation. Les annulations doivent être au maximum évitées pour ne pas bloquer les réservations d’autres utilisateurs.</p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header><span class="title">Remarques</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>Vous ne pouvez faire qu’une réservation à la fois.</p>
                  <p>Un Email de confirmation vous est envoyé avec les détails de la réservation au moment de la réservation mais aussi le jour avant la réservation.</p>
                  <p>Un Email vous est envoyé après une réservation pour savoir si tout s’est bien passé. Merci de bien vouloir nous répondre si il y a eu un problème de vélo ou autre.</p>
                  <p>Il se peut qu’il y ait encore des problèmes avec la plateforme de réservation car elle est encore en période de test. Aidez-nous à l’améliorer et partagez-nous les erreurs ou problèmes éventuels que vous y trouvez.</p>
                </v-expansion-panel-content>
              </v-expansion-panel>

                <v-expansion-panel>
                <v-expansion-panel-header><span class="title">Contacts</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>Si vous avez besoin de renseignements d’ordre pratique concernant les réservations, veuillez contacter Véronique Gallez (<a href="mailto:vgallez@stgilles.brussels">vgallez@stgilles.brussels</a>, 0488/87.86.23 )
                  <p>Si vous avez besoin de l’accompagnement de Pro Velo, veuillez contacter Cédric Favresse (<a ref="mailto:c.favresse@provelo.org">c.favresse@provelo.org</a>, 0498/50.20.00)</p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>


            <!-- Nederlands -->
            <v-expansion-panels v-if="userLang === 0">
              <v-expansion-panel
              >
                <v-expansion-panel-header><span class="title">Doelstelling van de Vélotheek</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>De Vélotheek heeft als uitgangspunt om gratis fietsen te lenen aan de scholen in Sint-Gillis om hen te ondersteunen in het organiseren van fietseducatie. Dit is voornamelijk gericht op leerkrachten lichamelijke opvoeding om fietsbehendigheid te kunnen opnemen in hun leertraject. Desalniettemin richten we ons tot alle leerkrachten om de fietsen te gebruiken.</p>
                  <p>Meer dan enkel het behalen van eindtermen is dit project ook in het leven geroepen zodat kinderen leren zich zelfstandig te verplaatsen met de fiets in de stad.</p>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel
              >
                <v-expansion-panel-header><span class="title">Begeleiding van Pro Velo, autonomie:</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>Het is niet altijd eenvoudig om een fietsles te geven of een uitstap op de fiets te begeleiden als je dat nog nooit gedaan hebt. Om hiermee te starten, kan u beroep doen op Pro Velo. Zij kunnen u bijstaan met kennis, advies en begeleiding. Indien u gebruik wenst te maken van de hulp van Pro Velo, vraag dit dan tijdig aan; de agenda wordt snel opgevuld. Een vijftigtal begeleidingsdagen worden gedurende de eerste drie jaren (2020 – 2022) voor de scholen van Sint-Gillis voorzien. U kan u van deze dienst gebruik maken.</p>
                  <p>Uiteindelijk is het de bedoeling dat u zelfstandig fietsanimaties en fietsuitstappen organiseert (zonder Pro Velo).</p>

                </v-expansion-panel-content>
              </v-expansion-panel>


              <v-expansion-panel
              >
                <v-expansion-panel-header><span class="title">Algemeen gebruik van de Vélotheek</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                    
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Fietsen ophalen op de dag zelf</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>De dag waarop u fietsen reserveerde, gaat u met uw klas naar de gekozen afdeling van de Vélotheek om de fietsen op te halen. U geeft de fietsles of maakt een uitstap met de fiets en brengt de fietsen daarna terug naar de Vélotheek.</p>

                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Gepaste plaatsen om een fietsles te geven</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          U kan een fietsles geven op de speelplaats (indien deze groot genoeg is) of in de openbare ruimte, bijvoorbeeld op het Moricharplein, Jacques Franckplein, Bethlehemplein… U kan ook een fietstocht maken.
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Laat leerlingen zo veel mogelijk hun eigen materiaal gebruiken</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          Het is belangrijk om systematisch aan de kinderen te vragen hun eigen fiets, helm en fluo hesje mee te brengen. De Vélotheek heeft als doel fietsen te voorzien voor kinderen die er geen hebben of die ze niet tot op school krijgen.
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">De ordening van de fietsen in de Vélotheek</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Gelieve de fietsen na gebruik op hun pikkel te zetten en per kleurencode te rangschikken op een compacte manier. Wij zijn jullie dankbaar voor een aangenaam/vlot gebruik van de Vélotheek.</p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Zorg dragen voor het materiaal</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          Wij vragen u om goed zorg te dragen voor het beschikbare materiaal. Alle fietsen zijn voorzien van een pikkel zodat ze niet op de grond hoeven te liggen. Gelieve deze dus te gebruiken. Daarnaast, verwachten we dat de leerlingen niet zonder toezicht met de fietsen spelen, dit veroorzaakt meestal botsingen tussen de fietsen, waardoor ze beschadigd worden. Bovendien mogen de kinderen niet slippen met de fietsen zodat bandenslijtage vermeden wordt. In de openbare ruimte moet er opgelet worden dat er geen fietsen of ander materiaal gestolen wordt.
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">In het geval van een beschadigde fiets</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          Het is volkomen normaal dat fietsen beschadigd worden; zeker wanneer kinderen pas leren fietsen (lekke band, versleten remmen, …). Om een goede werking voor alle klassen te garanderen is het belangrijk dat u ons hier zo snel mogelijk van op de hoogte brengt zodat wij de fietsen snel kunnen herstellen. Om dat te kunnen doen, brengt u ons op de hoogte via de mail die u na elke uitleen ontvangt. Daarin kan u het nummer van de beschadigde fiets(en) aangeven en wat het probleem precies is. In de fysieke Vélotheek is er een plaats voorzien waar kapotte fietsen geplaatst kunnen worden. Dit voorkomt dat een volgende groep op pad gaat met een fiets die niet werkt.
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>


                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Materiaal ter beschikking in de Vélotheek
</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          Op dit reservatieplatform vindt u terug welke fietsen er beschikbaar zijn per locatie. Op de locatie zelf, zijn de groottes van de fietsen aangegeven met een kleurcode op het stuur. De kleurencodes indiceren het volgende:
                        </p>
                        <p>
                          <ul>
                            <li>Loopfietsen: geen kleurcode (Alleen in Peter Pan )</li>
                            <li>Fietsen  16 inch: lichaamslengte ± 105 cm : ROOD (Alleen in Peter Pan)</li>
                            <li>Fietsen 20 inch: lichaamslengte ± 115 cm : BLAUW</li>
                            <li>Fietsen 24 inch: lichaamslengte ± 130 cm : GEEL</li>
                            <li>Fietsen 26 inch: lichaamslengte ± 145 cm : ORANJE</li>
                            <li>Fietsen voor volwassenen : > dan 145 cm : WIT</li>
                          </ul>
                        </p>
                        <p>
                          Naast de fietsen zijn er ook helmen en sloten beschikbaar Om kleine herstellingen uit te voeren staat er een Een fietspomp en enkele sleutels ter uw beschikking.
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>


                  
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>


              <v-expansion-panel>
                <v-expansion-panel-header><span class="title">Toegang tot de Vélotheek in de school ‘les 4 saisons’</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Openingsuren</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Tijdens de volgende uren is het mogelijk om fietsen op te halen en terug te brengen naar de school:</p>
                        <ul>
                          <li>Vanaf 8:30 tot 12:30 en vanaf 14:00 tot 15:45 op maandag, dinsdag, donderdag en vrijdag</li>
                          <li>Vanaf 8:30 tot 11:00 op woensdag</li>
                        </ul>
                        <p>Om de school niet te belasten, vragen we u om geen fietsen te wisselen nadat u ze reeds heeft uitgeleend. Indien u op een later tijdstip terugkeert van een fietsuitstap – die niet meer binnen de openingsuren van de school valt- gelieve ons dan tijdig op de hoogte te brengen.</p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Toegang tot de Vélotheek</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Op de dag van uw reservering moet u aanbellen bij het secretariaat van école 4 Saisons (Betlehemplein 10, 1060 St Gillis). Vermeld uw identiteit en het feit dat u voor de Vélotheek komt. U haalt de sleutels van de Vélotheek op bij het schoolsecretariaat, verlaat de school en gaat iets verderop tot de Denemarkenstraat. De deur van de Vélotheek bevindt zich in de Denemarkenstraat, twee deuren verder vanbij de ingang de schoolpoort. Daarboven staat "Cité des jeunes". De fietsen worden gestald in een lokaal aan de linkerkant, zo'n 20 meter verderop in de gang. Een bordje "Vélotheek" op de deur geeft aan dat u zich op de juiste plaats bevindt. De lichtschakelaar voor de kamer bevindt zich links 2 m na de deur. Vergeet niet om zowel het licht van het lokaal ui te schakelen als de straatdeur op slot te doen. U geeft dan de sleutels aan het secretariaat voordat u gaat fietsen.</p>
                        <p>Om de fietsen terug te brengen, bel je weer aan bij het secretariaat van de school om de sleutels te vragen en de fietsen terug te plaatsen. Vergeet niet om na gebruik de lichten uit te doen en de deuren goed op slot te doen. Nadien geeft u de sleutels terug aan het secretariaat.</p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>


              <v-expansion-panel>
                <v-expansion-panel-header><span class="title">Accès à la Vélotheek Peter Pan</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Openingsuren</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Tijdens de volgende uren is het mogelijk om fietsen op te halen en terug te brengen naar de school ‘Peter Pan’:
                        <ul>
                          <li>Vanaf 9:00 tot 12:00 en vanaf 14:00 tot 15:00 op maandag, dinsdag, donderdag en vrijdag</li>
                          <li>Vanaf 9:00 tot 11:00 op woensdag</li>
                        </ul>
                        <p>Om de school niet te belasten, vragen we u om geen fietsen te wisselen nadat u ze reeds heeft uitgeleend. Indien u op een later tijdstip terugkeert van een fietsuitstap – die niet meer binnen de openingsuren van de school valt- gelieve ons dan tijdig op de hoogte te brengen.</p> 
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Toegang tot de Vélotheek</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Op de dag van uw reservatie, belt u aan bij het secretariaat van de school Peter Pan (Retoricastraat 13). Gelieve uzelf te identificeren en te melden dat u voor de Vélotheek komt. Dan zal de deur open gaan en de sleutels die u toegang geven tot het lokaal van de Vélotheek hangen links in een kastje tegen de muur. De toegangscode is 1060. Wees voorzichtig om de code niet te onthullen en de cijfers terug van plaats te wisselen. Het lokaal van de fietsen bevindt zich achter de tweede deur rechts na de toegangspoort. Na de metalen deur, bevinden de fietsen zich op het einde van de gang. Na het ophalen van de fietsen, ga je naar buiten op het voetpad, vergeet niet het licht uit te doen en de deur achter je op slot te doen. U houdt de sleutels bij gedurende de fietsles/fietsactiviteit.</p>
                        <p>Na de fietsles, kan u met de sleutels binnen gaan in het lokaal van de Vélotheek en het materiaal op een gepaste manier terugzetten. Vergeet niet om bij het verlaten van de Vélotheek het licht uit te schakelen en de fietsenkot op slot te doen. Daarna belt u aan bij het secretariaat van de school. Wanneer u binnen gelaten wordt, kan u de sleutel terug in het kluisje tegen de muur stoppen. Vergeet niet de cijfers terug van plaats te wisselen nadat je de sleutels hebt ingeleverd.</p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header><span class="title">Werkwijze van het reserveringssysteem</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>Wanneer u ingelogd bent op het reserveringsplatform, klinkt u op ‘nieuwe reservatie’ of ‘mijn reservaties’ bovenaan de pagina.</p>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Een nieuwe reservatie</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Een nieuwe reservering in 5 stappen</p>
                        <ol>
                          <li><span class="font-weight-bold">Kies de juiste locatie:</span> u kunt ‘Les 4 saisons’ (laag Sint-Gillis) of ‘Peter Pan’ (hoog Sint-Gillis) kiezen.</li>
                          <li><span class="font-weight-bold">Kies de gewenste data:</span> klik op een datum. De beschikbare momenten zijn in het groen aangeduid in de agenda:
                            <ul>
                              <li>Volledige, groene bol = volledige dag beschikbaar</li>
                              <li>Halve, groene bol – links = voormiddag beschikbaar</li>
                              <li>Halve, groene bol – rechts = namiddag beschikbaar</li>
                              <li>Grijs = geen fietsen beschikbaar</li>
                            </ul>
                          </li>
                          <li><span class="font-weight-bold">Kies uw tijdsslot:</span> Klik op het tijdstip dat voor u past (voormiddag, namiddag of volledige dag). Wanneer een tijdsslot niet (meer) beschikbaar is, is het doorgestreept.</li>
                          <li><span class="font-weight-bold">Kies de fietsen:</span> klink op de fietsen die u wenst te reserveren. U kan een menu uitrollen met extra informatie over de fietsen zoals de gemiddelde grote van het kind en de leeftijd. Helaas is het niet steeds evident om het correcte aantal fietsen van de juiste grootte te reserveren. Maakt u zich geen zorgen. Wanneer u toegang hebt tot het lokaal van de Vélotheek, kan u steeds enkele fietsen wisselen in functie van de noden van uw leerlingen.</li>
                          <li>Wanneer u de gewenste fietsen heeft gereserveerd, klik dan op ‘reserveren’.</li>
                        </ol>
                        <p>Nogmaals: om de scholen niet te zwaar te belasten, vragen wij u om geen fietsen om te wisselen tijdens activiteiten en indien uw terugkeer later valt dan de aangegeven toegangsuren, gelieve ons dan op tijd te verwittigen. Zo kunnen we een gepaste oplossing zoeken.</p> 
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header><span class="subtitle-1">Uw reservaties</span></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Op de pagina ‘uw reservaties’, krijgt u een overzicht van de gereserveerde fietsen op de gekozen data. U ontvangt hiervoor ook een bevestigingsmail. Wanneer er een activiteit niet kan plaats vinden, kan u deze op deze pagina annuleren. Gelieve zo weinig mogelijk activiteiten te annuleren. Dit om te voorkomen dat andere scholen de fietsen tijdig kunnen reserveren voor hun fietsles.</p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header><span class="title">Opmerkingen</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>Wanneer u fietsen reserveert, is dat voor één les/uitstap. Nadat u de reservatie heeft voltooid, krijgt u een bevestigingsmail met een herhaling van alle informatie. Ook de dag voor de geplande activiteit ontvangt u nog een herinneringsmail.</p>
                  <p>Nadat u de fietsen heeft gebruikt krijgt u een mail met de vraag of alles goed verlopen is. Gelieve hierop te antwoorden indien er een fiets stuk is of er iets is misgelopen.</p>
                  <p>Gezien we voorlopig nog in de beginfase zitten met het platform, is alle feedback welkom zo dat we zo snel mogelijk onze diensten kunnen optimaliseren.</p>
                </v-expansion-panel-content>
              </v-expansion-panel>

                <v-expansion-panel>
                <v-expansion-panel-header><span class="title">Contact</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>Indien u hulp nodig/vragen/bedenkingen heeft in verband met het maken van reservaties, gelieve contact op te nemen met Anna Thijs (<a ref="mailto:athijs@stgilles.brussels">athijs@stgilles.brussels</a>, 0490/52 30 09 )</p>
                  <p>Indien u hulp nodig/vragen heeft over een begeleiding van Pro Velo, gelieve contact op te nemen met Florent Buchel (<a ref="mailto:f.buchel@provelo.org">f.buchel@provelo.org</a>, 0484/50 69 39)</p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-container>
</template>

<script>
import userBookings from '@/components/User-Bookings'
import { mapState } from 'vuex'

export default {
  components: { userBookings },
  head: function() {
    return {
      title: {
        inner: 'Guide'
      },
      meta: [
        {
          name: 'description',
          content: `Guide`,
          id: 'desc'
        }
      ]
    }
  },
  computed: {
    ...mapState('authentication', ['userLang'])
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/animate.scss';
.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    margin-bottom: 3rem;
  }

  .home-page-title {
    text-align: center;
  }

  .documentation-link {
    display: inline-block;
    font-size: 1.2rem;
    color: #fff;
    background-color: #5d6788;
    padding: 0.8rem 1.6rem;
    border-radius: 4px;
    transition: background-color 0.1s ease;
    box-sizing: border-box;
    text-decoration: none;
    width: fit-content;
    font-weight: 500;
  }
}
</style>
