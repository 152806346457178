<template>
  <div>
    <div>
      <!-- <input
        class="ma-2"
        outlined
        type="file"
        @change="previewFile"
        
      > -->
        <v-file-input  prepend-icon="mdi-image" @change="previewFile" type="file" label="File input" accept="image/x-png,image/gif,image/jpeg*" outlined dense></v-file-input>
    </div>
    <div>
      <!-- Progress: {{uploadValue.toFixed()+"%"}} -->
        <v-progress-linear value="uploadValue"></v-progress-linear>
        <!-- <progress
          id="progress"
          :value="uploadValue"
          max="100"
        ></progress> </p> -->
    </div>
    <!-- {{ fileData }} -->
    <div v-if="fileData!=null">
      <img
        class="preview"
        :src="uploadedFile"
      >
      <br>
      <v-btn
        class="ma-2"
        outlined
        @click="onUpload"
      >
      Upload
      </v-btn>
    </div>
  </div>
</template>


<script>
/** TODO: change name on opload */
import firebase from 'firebase'
export default {
  name: 'Upload',
  props: {
    accept: { type: String, default: '*' }
  },
  data() {
    return {
      fileData: null,
      picture: null,
      uploadValue: 0
    }
  },
  methods: {
    previewFile(file) {
      
      this.uploadValue = 0
      this.uploadedFile = null
      this.fileData = file
    },
    onUpload() {
      this.uploadedFile = null
      const storageRef = firebase
        .storage()
        .ref(`${this.fileData.name}`)
        .put(this.fileData)
        
      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
        error => {
          console.log(error.message)
        },
        () => {
          this.uploadValue = 100
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.uploadedFile = url
            this.$emit('uploaded', url)
          })
        }
      )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img.preview {
  width: 200px;
}
</style>