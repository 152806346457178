  <template>
  <div style="width:100%">

    <v-container>

      <!-- TODO: Translate -->
        <p class="admin-form__label">Stock</p>
        <admin-products-stock></admin-products-stock>
        <!-- <admin-products-stock v-if="productFunction == 'edit' && productToCreate.default.org.value != null"></admin-products-stock>
        <div v-else>
          Wanneer je het product opslaat kan je stock toevoegen.        
        </div> -->

        
      <!-- TODO: Does this work? -->
      <!--
      <v-row v-if="organisations">
        <v-col cols="12">
          <copy item="chooseOrganisation" :lang="userLang"></copy>
        </v-col>

        
        <v-btn
          v-for="(role, org) in user.organisations"
          :key="org"
          class="ma-2"
          :dark="productToCreate.default.org.value === org"
          :outlined="productToCreate.default.org.value !== org"
          @click="
            setProductToCreate({
              value: org,
              fieldName: 'org',
              lang: null
            })
          "
        >
          <template v-if="org">
            {{ getOrganisationById(org).name.value[userLang] }}
            <i>{{ getUserRoleById(role).name[userLang] }}</i>
          </template>
        </v-btn>
      </v-row>
      -->
      <!-- Row with basic info (fixed) -->
      <template v-if="productToCreate.default.org.value !== null">
        
        <v-row align="center">
          <v-col
            v-for="(field, fieldName) in productToCreate.default"
            :key="'default-' + fieldName"
            cols="12"
            sm="12"
            md="12"
          >
            <template v-if="field">
              <template v-if="field.type == 'string'">
                <p class="admin-form__label">{{ field.label[userLang] }}</p>
                <v-text-field
                  v-for="(l, lang) in orderedLanguages"
                  :key="l.id"
                  :label="l.name"
                  :placeholder="field.label[userLang]"
                  :value="productToCreate.default[fieldName].value[lang]"
                  outlined
                  @input="
                    setProductToCreate({
                      value: $event,
                      fieldName: fieldName,
                      lang: l.id
                    })
                  "
                ></v-text-field>
              </template>
              <template v-if="field.type == 'number'">
                <p class="admin-form__label">{{ field.label[userLang] }}</p>
                <v-text-field
                  :label="field.label[userLang]"
                  :placeholder="field.label[userLang]"
                  :value="productToCreate.default[fieldName].value"
                  outlined
                  @input="
                    setProductToCreate({
                      value: $event,
                      fieldName: fieldName,
                      lang: null
                    })
                  "
                ></v-text-field>
              </template>

              <template v-if="field.type === 'file'">
                <v-row align="start">
                  <v-col cols="6" sm="6" md="6"> 
                    <upload @uploaded="uploaded"></upload>
                  </v-col>
                  <v-col cols="4" sm="4" md="4" >
                    <v-img v-if="field.value" :src="field.value"></v-img>
                  </v-col>
                </v-row>
              </template>
            </template>
          </v-col>
        </v-row>

        <!-- Row with specific info (add and remove possible) -->
        <v-row align="center">
          <!-- TODO: Translate -->
          <p class="admin-form__label">Specs</p>
          <v-col
            v-if="productToCreate.specs.length == 0"
            cols="12"
            sm="12"
            md="12"
          >
            <p class="admin-zeroContent__text">
              <copy
                item="addProductNoSpecs"
                :lang="userLang"
              ></copy>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-card
            v-for="(field, fieldName) in productToCreate.specs"
            :key="'specs-' + fieldName"
            outlined
            style="width:100%;margin-bottom:1rem;"
          >
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >

                  <!-- The label should always be translated -->

                  <v-text-field
                    v-for="(l, lang) in orderedLanguages"
                    :key="lang"
                    :label="l.name"
                    :placeholder="field.label[userLang]"
                    :value="productToCreate.specs[fieldName].label[lang]"
                    outlined
                    @input="
                      setProductSpecToCreate({
                        value: $event,
                        fieldName: fieldName,
                        label: true,
                        lang: lang
                      })
                    "
                  ></v-text-field>

                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <template v-if="field.i18n">
                    <v-text-field
                      v-for="(l, lang) in orderedLanguages"
                      :key="lang"
                      :label="l.name"
                      :placeholder="field.value[userLang]"
                      :value="productToCreate.specs[fieldName].value[lang]"
                      outlined
                      @input="
                        setProductSpecToCreate({
                          value: $event,
                          fieldName: fieldName,
                          label: false,
                          lang: lang,
                        })
                      "
                    ></v-text-field>
                  </template>
                  <template v-else>
                    <v-text-field
                      :label="field.typeLabel[userLang]"
                      :placeholder="field.value[userLang]"
                      :value="productToCreate.specs[fieldName].value"
                      outlined
                      @input="
                        setProductSpecToCreate({
                          value: $event,
                          fieldName: fieldName,
                          label: false,
                          lang: null
                        })
                      "
                    ></v-text-field>

                  </template>
                </v-col>
              </v-row>
            </v-col>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn
                color="error"
                outlined
                @click="removeSpec(fieldName)"
              >
                <copy
                  item="remove"
                  :lang="userLang"
                ></copy>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-row>

        <admin-products-spec-add></admin-products-spec-add>

        
      </template>
    </v-container>

    <template v-if="
        productFunction == 'edit' && productToCreate.default.org.value != null
      ">
      <v-btn
        class="ma-2"
        outlined
        text
        @click="saveProduct"
      >
        <copy
          item="saveProduct"
          :lang="userLang"
        ></copy>
      </v-btn>
    </template>
    <!-- {{ productToCreate }} -->
    <template v-if="
        productFunction == 'create' && productToCreate.default.org.value != null
      ">
      <v-btn
        class="ma-2"
        outlined
        text
        @click="addProduct"
      >
        <copy
          item="addProduct"
          :lang="userLang"
        ></copy>
      </v-btn>
    </template>

    <v-btn
      class="ma-2"
      depressed
      small
      color="error"
      text
      @click="deletePrompt()"
    >
      <copy
        item="remove"
        :lang="userLang"
      ></copy>
    </v-btn>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import AdminProductsSpecAdd from '@/components/Admin-ProductsSpecAdd'
import AdminProductsStock from '@/components/Admin-ProductsStock'
import upload from '@/components/Utils-Upload'

export default {
  components: { AdminProductsSpecAdd, AdminProductsStock, upload },
  computed: {
    ...mapState('adminProducts', [
      'productToCreate',
      'productCreationPending',
      'productFunction'
    ]),
    // ...mapState('translations', ['languages']),
    ...mapState('authentication', ['userLang', 'user']),
    ...mapState('adminOrganisations', ['organisations']),
    ...mapState('adminProducts', ['stock']),
    ...mapGetters('adminOrganisations', ['getOrganisationById']),
    ...mapGetters('adminUsers', ['getUserRoleById']),
    ...mapGetters('translations', ['orderedLanguages'])
  },
  methods: {
    ...mapMutations('adminProducts', [
      'setProductToCreate',
      'setProductSpecToCreate',
      'removeSpec'
    ]),
    ...mapActions('adminProducts', [
      'triggerAddProductAction',
      'addProduct',
      'saveProduct',
      'deleteProduct'
    ]),
    ...mapActions('adminOrganisations', ['getOrganisations']),
    deletePrompt() {
      if (this.stock.length === 0) {
        confirm('Delete Product?') &&
          this.deleteProduct({ productId: this.productToCreate.id })
      } else {
        confirm(`Can't delete this product because there is still some stock.`)
      }
    },
    uploaded(url) {
      this.setProductToCreate({
        value: url,
        fieldName: 'image',
        lang: null
      })
      // conso    le.log(e)
    }
  },
  created() {
    this.getOrganisations()
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/admin.scss';
</style>
