<template>
  <v-container fluid>
    <v-row align="start" justify="start" class="grey lighten-5">
      <v-col cols="12" sm="12" md="12">
        <admin-users-list></admin-users-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import AdminUsersList from '@/components/Admin-UsersList'

export default {
  components: { AdminUsersList },
  data: () => ({
    drawer: null
  })
}
</script>
