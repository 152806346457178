var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"outlined":""},on:{"click":function($event){return _vm.setNewSpecDefaults(_vm.spec)}}},on),[_vm._v("\n      "+_vm._s(_vm.spec.typeLabel[_vm.userLang])+"\n    ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_c('span',{staticClass:"admin-spec__title"},[_c('copy',{attrs:{"item":"addSpecsaveProduct","lang":_vm.userLang}})],1),_c('span',{staticClass:"admin-spec__title--specName"},[_vm._v("\n        "+_vm._s(_vm.spec.typeLabel[_vm.userLang])+"\n      ")])]),(_vm.newSpec != null)?_c('v-card-text',[_c('v-form',[_c('v-container',[_c('v-row',{attrs:{"align":"start"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('p',{staticClass:"admin-form__label"},[_c('copy',{attrs:{"item":"specLabel","lang":_vm.userLang}})],1),_vm._l((_vm.languages),function(l,lang){return _c('v-text-field',{key:'specLabelLang-' + lang,attrs:{"label":l.name,"placeholder":_vm.copy('specLabel', _vm.userLang),"value":_vm.newSpec.label[lang],"outlined":""},on:{"input":function($event){return _vm.setNewSpecField({
                    value: $event,
                    fieldName: 'label',
                    lang: lang
                  })}}})})],2),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[(_vm.newSpec.i18n)?[_c('p',{staticClass:"admin-form__label"},[_c('copy',{attrs:{"item":"specValue","lang":_vm.userLang}})],1),_vm._l((_vm.languages),function(l,lang){return _c('v-text-field',{key:'specValueLang-' + lang,attrs:{"label":l.name,"placeholder":_vm.copy('specValue', _vm.userLang),"value":_vm.newSpec.value[lang],"outlined":""},on:{"input":function($event){return _vm.setNewSpecField({
                      value: $event,
                      fieldName: 'value',
                      lang: lang
                    })}}})})]:[_c('p',{staticClass:"admin-form__label"},[_c('copy',{attrs:{"item":"specValue","lang":_vm.userLang}})],1),(_vm.newSpec.type == 'string')?[_c('v-text-field',{attrs:{"placeholder":_vm.copy('specValue', _vm.userLang),"value":_vm.newSpec.value,"outlined":""},on:{"input":function($event){return _vm.setNewSpecField({
                        value: $event,
                        fieldName: 'value',
                        lang: null
                      })}}})]:_vm._e(),(_vm.newSpec.type == 'number')?[_c('v-text-field',{attrs:{"placeholder":_vm.copy('specValue', _vm.userLang),"value":_vm.newSpec.value[0],"outlined":""},on:{"input":function($event){return _vm.setNewSpecField({
                        value: $event,
                        fieldName: 'value',
                        lang: null
                      })}}})]:_vm._e(),(_vm.newSpec.type == 'range')?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"placeholder":_vm.copy('specValue', _vm.userLang) + ' 1',"value":_vm.newSpec.value[0][0],"outlined":""},on:{"input":function($event){return _vm.setNewSpecField({
                            value: [$event, _vm.newSpec.value[0][1]],
                            fieldName: 'value',
                            lang: null
                          })}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"placeholder":_vm.copy('specValue', _vm.userLang) + ' 2',"value":_vm.newSpec.value[0][1],"outlined":""},on:{"input":function($event){return _vm.setNewSpecField({
                            value: [_vm.newSpec.value[0][0], $event],
                            fieldName: 'value',
                            lang: null
                          })}}})],1)],1)]:_vm._e()]],2)],1)],1)],1)],1):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"error","outlined":""},on:{"click":_vm.cancelSpecCreationAndClose}},[_c('copy',{attrs:{"item":"cancel","lang":_vm.userLang}})],1),_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","text":""},on:{"click":_vm.addSpecAndClose}},[_c('copy',{attrs:{"item":"add","lang":_vm.userLang}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }