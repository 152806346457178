<template>
  <v-card style="width:100%">
    <v-list-item three-line>
      <v-list-item-content>
        <!-- <div class="overline mb-4">
          {{ booking.dateRange.start | moment('from', 'now') }}
        </div> -->
        <v-list-item-title class="headline mb-1">
          {{ booking.dateRange.start | moment('D MMMM YYYY') }}
          <span v-if="!isSameDate(booking.dateRange.start, booking.dateRange.end)"> - {{ booking.dateRange.end | moment('D MMMM YYYY') }}</span>
        </v-list-item-title>
        <v-list-item-subtitle>
            <template v-if="booking.freeTimeBooking">
              <span v-if="booking.freeTimeBooking.start">Ophalen: {{ booking.freeTimeBooking.start }}</span>
              <p><span v-if="booking.freeTimeBooking.end">Terugbrengen:{{ booking.freeTimeBooking.end }}</span></p>
            </template>
            <span v-if="booking.timeslots[0].value && booking.timeslots[1].value">
                {{ booking.timeslots[0].name[userLang] }} + {{ booking.timeslots[1].name[userLang] }}
            </span>
            <div v-else>
              <span v-if="booking.timeslots[0].value">
                {{ booking.timeslots[0].name[userLang] }}
              </span>
              <span v-if="booking.timeslots[1].value">
                {{ booking.timeslots[1].name[userLang] }}
              </span>
            </div>

          <div>
            <copy item="numberOfProducts" :lang="userLang"></copy>: {{ booking.totalProducts }}

            
              <v-expansion-panels class="pa-1">
    <v-expansion-panel
      
    >
      <v-expansion-panel-header v-if="booking.mail"><copy item="viewMail" :lang="userLang"></copy></v-expansion-panel-header>
      <v-expansion-panel-content>
        <div style="white-space: pre-line;" >{{booking.mail.body}}</div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
          </div>
          <!-- Ref: {{ booking.id }} -->
        </v-list-item-subtitle>
      </v-list-item-content>
      
    </v-list-item>

    <v-card-actions>
      <!-- TODO: add this -->
      <!-- <v-btn @click="viewBookingsEmail({ bookingId: booking.id })">
        <copy item="viewMail" :lang="userLang"></copy></v-btn> -->
      <v-btn @click="sendBookingEmail({ bookingId: booking.id })">
        <copy item="resendMail" :lang="userLang"></copy>
      </v-btn>
      <v-btn @click="deletePrompt(booking.id)">
        <span><copy item="remove" :lang="userLang"></copy>
        </span>
      </v-btn>

      <!-- <v-btn @click="editBooking({ bookingId: booking.id })"> -->
        <!-- TODO: Translate -->
        <!-- Edit -->
      <!-- </v-btn> -->
    </v-card-actions>
    
    <!-- <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>Item</v-expansion-panel-header>
        <v-expansion-panel-content>
          {{ booking.mail.body}}
          <span v-html="booking.mail.body">
          </span>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->

  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import router from '@/router'
import moment from 'moment'

export default {
  props: {
    booking: Object
  },
  computed: {
    ...mapState('authentication', ['userLang']),
    
  },
  methods: {
    ...mapActions('adminBookings', ['sendBookingEmail', 'editBooking', 'cancelBooking', 'listenForBookingUpdatesUser', 'getEmailBody']),
    deletePrompt(item) {
      confirm('Cancel Booking?') &&
        this.cancelBooking({ bookingId: item })
    },
    isSameDate(date1, date2){

      return moment(date1).isSame(moment(date2))
    },
  },
  mounted () {
    /* TODO: Use callback of delete function rather then updates */
    this.listenForBookingUpdatesUser()
;
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/admin.scss';
</style>
