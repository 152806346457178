<template>
  <div>
    <admin-users-organisation-detail
      :edit="false"
    ></admin-users-organisation-detail>

    <v-card v-if="selectedUser.organisations && organisations" tile>
      <v-container>
        <v-row
          v-for="(role, org) in selectedUser.organisations"
          :key="role + org"
        >
          <v-col cols="3">
            {{ getOrganisationById(org).name.value[userLang]}}
          </v-col>
          <v-col cols="5">
            <!-- TODO: Translate -->
            <v-select
              :items="userRoles"
              outlined
              :label="getUserRoleById(role).name[userLang]"
              @input="
                editUserOrganisations({
                  organisation: org,
                  role: $event
                })
              "
            >
              <template v-slot:selection="{ item, id }">
                {{ item.name[userLang] }}
              </template>
              <template v-slot:item="{ item, id }">
                {{ item.name[userLang] }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="3">
            <v-btn
              class="ma-2"
              color="error"
              outlined
              @click="removeUserOrganisation({orgId: org})"
            >
              <!-- TODO: Translate -->
              Remove Organisation
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import AdminUsersOrganisationDetail from '@/components/Admin-UsersOrganisationDetail'

export default {
  components: { AdminUsersOrganisationDetail },
  data() {
    return {
      dialog: false,
      selectedOrg: null,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Id', value: 'id' },
        { text: 'Role', value: 'role' },
        { text: 'Actions', value: 'action', sortable: false, align: 'center' }
      ]
    }
  },
  computed: {
    ...mapState('adminUsers', [
      'userOrganisations',
      'selectedUser',
      'userRoles'
    ]),
    ...mapState('adminOrganisations', ['organisations']),
    ...mapGetters('adminOrganisations', ['getOrganisationById']),
    ...mapGetters('adminUsers', ['getUserRoleById']),
    ...mapState('authentication', ['userLang'])
  },
  methods: {
    ...mapActions('adminUsers', ['removeUserOrganisation']),
    ...mapActions('adminOrganisations', [
      'removeUserFromOrganisation',
      'getOrganisations'
    ]),
    ...mapMutations('adminUsers', ['editUserOrganisations']),
    deletePrompt(item) {
      // confirm(
      //   this.adminCopy['deleteOrganisationFromUser'].value[this.userLang]
      // ) && this.deleteItem({ orgId: item.orgId })
    },
    deleteItem({ orgId: orgId, userId: userId }) {
      // TODO: Use props
      this.removeOrganisationFromUser({
        orgId: orgId,
        userId: userId
      })
      this.removeUserFromOrganisation({
        orgId: orgId
      })
    },
    created() {
      this.getOrganisations()
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
