var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('admin-users-organisation-detail',{attrs:{"edit":false}}),(_vm.selectedUser.organisations && _vm.organisations)?_c('v-card',{attrs:{"tile":""}},[_c('v-container',_vm._l((_vm.selectedUser.organisations),function(role,org){return _c('v-row',{key:role + org},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v("\n          "+_vm._s(_vm.getOrganisationById(org).name.value[_vm.userLang])+"\n        ")]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-select',{attrs:{"items":_vm.userRoles,"outlined":"","label":_vm.getUserRoleById(role).name[_vm.userLang]},on:{"input":function($event){return _vm.editUserOrganisations({
                organisation: org,
                role: $event
              })}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
              var id = ref.id;
return [_vm._v("\n              "+_vm._s(item.name[_vm.userLang])+"\n            ")]}},{key:"item",fn:function(ref){
              var item = ref.item;
              var id = ref.id;
return [_vm._v("\n              "+_vm._s(item.name[_vm.userLang])+"\n            ")]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"error","outlined":""},on:{"click":function($event){return _vm.removeUserOrganisation({orgId: org})}}},[_vm._v("\n            Remove Organisation\n          ")])],1)],1)}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }