<template>
  <div style="width:100%">
    <v-form>
      <v-container>
        
        <!-- Row with basic info (fixed) -->
        <v-row align="center">
          <v-col
            v-for="(field, fieldName) in orgToCreate"
            :key="'organisation-' + fieldName"
            cols="12"
            sm="12"
            md="12"
          >
          
          
            <template v-if="field.label">
              <p class="admin-form__label">{{ field.label[userLang] }}</p>

              <v-text-field
                v-for="(l, lang) in languages"
                :key="'organisationlang-' + lang"
                :label="l.name"
                :placeholder="field.label[userLang]"
                :value="orgToCreate[fieldName].value[lang]"
                outlined
                @input="
                  setOrganisationToCreate({
                    value: $event,
                    fieldName: fieldName,
                    lang: lang
                  })
                "
              ></v-text-field>
            </template>
          </v-col>
        </v-row>

        <!-- configure mails -->

        <h3>Mails</h3>
        <v-row
          v-for="(mail, mailId) in orgToCreate.mails"
          :key="'mails-' + mailId"
          align="center"
        >
        
          <v-col
            v-for="(field, fieldName) in mail"
            :key="'mailfield-' + fieldName"
            cols="12"
            sm="12"
            md="12"
          >
            <template v-if="fieldName == 'name'">
              <h4>Verstuurd bij: {{ field[userLang] }}</h4>
            </template>
            <template v-else-if="fieldName == 'descr'">
              <p>{{ field[userLang] }}</p>
            </template>
            <template v-else-if="fieldName == 'subject'">
              
              <v-text-field
                v-for="(l, lang) in languages"
                :key="'subject' + lang + fieldName + lang"
                :label="fieldName"
                :placeholder="fieldName"
                :value="orgToCreate.mails[mailId].subject.value[lang]"
                outlined
                @input="
                  setMailForOrganisation({
                    value: $event,
                    fieldName: fieldName,
                    lang: lang,
                    mailId: mailId,
                    partId: null
                  })
                "
              ></v-text-field>
            </template>
            <template v-else-if="fieldName == 'parts'">
              <template v-for="(part, partId) in mail.parts">
                <v-text-field
                  v-for="(l, lang) in languages"
                  :key="'mailpart' + partId + lang"
                  :label="part.label[lang]"
                  :placeholder="fieldName"
                  :value="orgToCreate.mails[mailId].parts[partId].value[lang]"
                  outlined
                  @input="
                    setMailForOrganisation({
                      value: $event,
                      fieldName: fieldName,
                      lang: lang,
                      mailId: mailId,
                      partId: partId
                    })
                  "
                ></v-text-field>
              </template>
            </template>
          </v-col>
        </v-row>

        <admin-organisations-locations></admin-organisations-locations>
      </v-container>
    </v-form>

    <template v-if="orgFunction == 'edit'">
      <v-btn class="ma-2" outlined text @click="saveOrg">
        <copy item="saveOrganisation" :lang="userLang"></copy>
      </v-btn>
    </template>

    <template v-if="orgFunction == 'create'">
      <v-btn class="ma-2" outlined text @click="addOrg">
        <copy item="addOrganisation" :lang="userLang"></copy>
      </v-btn>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import AdminOrganisationsLocations from '@/components/Admin-OrganisationsLocations'

export default {
  components: { AdminOrganisationsLocations },
  computed: {
    ...mapState('adminOrganisations', ['orgToCreate', 'orgFunction']),
    ...mapState('translations', ['languages']),
    ...mapState('authentication', ['userLang'])
  },
  methods: {
    ...mapMutations('adminOrganisations', [
      'setOrganisationToCreate',
      'setMailForOrganisation'
    ]),
    ...mapActions('adminOrganisations', ['addOrg', 'saveOrg'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/admin.scss';
</style>
