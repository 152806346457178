var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.edit)?_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"outlined":""},on:{"click":_vm.startCreatingNewUser}},on),[_c('copy',{attrs:{"item":"addUser","lang":_vm.userLang}})],1):_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.setUserToEdit(_vm.user)}}},on),[_vm._v("\n      mdi-pencil\n    ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[(!_vm.edit)?_c('span',[_c('copy',{attrs:{"item":"addUser","lang":_vm.userLang}})],1):_c('span',[_c('copy',{attrs:{"item":"editUser","lang":_vm.userLang}})],1)]),_c('v-card-text',[_c('v-form',[_c('v-container',[_c('v-row',{attrs:{"align":"center"}},_vm._l((_vm.selectedUser),function(field,fieldName){return _c('v-col',{key:'default-' + fieldName,attrs:{"cols":"12","sm":"12","md":"12"}},[(field.type == 'select')?[_c('v-select',{attrs:{"value":_vm.selectedUser[fieldName].value,"items":_vm.languages,"label":field.label[_vm.userLang],"item-value":fieldName,"outlined":""},on:{"input":function($event){return _vm.setSelectedUser({
                      value: $event,
                      fieldName: fieldName
                    })}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var id = ref.id;
return [_vm._v("\n                    "+_vm._s(item.name)+"\n                  ")]}},{key:"item",fn:function(ref){
                    var item = ref.item;
                    var id = ref.id;
return [_vm._v("\n                    "+_vm._s(item.name)+"\n                  ")]}}],null,true)})]:_vm._e(),(field.label && field.type != 'select')?[_c('p',{staticClass:"admin-form__label"},[_vm._v(_vm._s(field.label[_vm.userLang]))]),_c('v-text-field',{attrs:{"placeholder":field.label[_vm.userLang],"value":_vm.selectedUser[fieldName].value,"outlined":""},on:{"input":function($event){return _vm.setSelectedUser({
                      value: $event,
                      fieldName: fieldName
                    })}}})]:_vm._e()],2)}),1),_c('admin-users-organisations')],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_c('copy',{attrs:{"item":"cancel","lang":_vm.userLang}})],1),_c('div',{staticClass:"flex-grow-1"}),_vm._v("\n      "+_vm._s(_vm.edit)+"\n      "),(_vm.edit)?[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","text":""},on:{"click":_vm.updateUserAndCloseDialog}},[_c('copy',{attrs:{"item":"saveUser","lang":_vm.userLang}})],1)]:[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","text":""},on:{"click":_vm.addUserAndCloseDialog}},[_c('copy',{attrs:{"item":"addUser","lang":_vm.userLang}})],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }