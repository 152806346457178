<template>
  <div>
    <p v-if="products === null" class="infos-label">Loading...</p>
    <p v-if="products && !products.length" class="infos-label">
      You don't have any products yet
    </p>

    <v-btn class="ma-2" block outlined text @click="startCreatingNewProduct">
      <copy item="addProduct" :lang="userLang"></copy>
    </v-btn>

    <v-list two-line subheader>
      <v-list-item
        v-for="product in products"
        :key="'product-' + product.id"
        @click="setProductToEdit({ product: product })"
      >
        <v-list-item-content>
          <v-list-item-title
            v-text="product.default.name.value[userLang]"
          ></v-list-item-title>
          <v-list-item-subtitle v-text="product.id"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('adminProducts', ['isProductDeletionPending']),
    ...mapState('adminProducts', ['products', 'stock']),
    ...mapState('translations', ['languages']),
    ...mapState('authentication', ['userLang']),
    ...mapState('app', ['networkOnLine'])
  },
  methods: {
    ...mapActions('adminProducts', [
      'deleteProduct',
      'setProductToEdit',
      'startCreatingNewProduct'
    ])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.infos-label {
  text-align: center;
}

.product-row {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 10px auto;
  justify-content: space-between;
}
</style>
