<template>
  <v-container fluid>
    <v-row align="start" justify="start" class="grey lighten-5">
      <v-col cols="12" sm="12" md="12">
        <admin-bookings-list></admin-bookings-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import adminBookingsList from '@/components/Admin-BookingsList'

export default {
  components: {
    adminBookingsList
  },
  computed: {
    ...mapState('app', ['networkOnLine'])
  }
}
</script>
