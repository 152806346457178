<template>
  <div>
                <h2 class=".headline"><copy item="welcome" :lang="userLang"></copy></h2> 
    <!-- <v-btn
      class="ma-2"
      outlined
      @click="nav({ path: '/booking', editing: null })"
    >
      <copy item="startNewBooking" :lang="userLang"></copy>
    </v-btn> -->
    <br>
    <div v-if="!userActiveBookings || userActiveBookings.length === 0">
      <copy item="noBookings" :lang="userLang"></copy>
    </div>

    <user-booking-detail
      v-for="booking in userActiveBookings"
      :key="booking.id"
      :booking="booking"
      style="margin-bottom:2rem;"
    ></user-booking-detail>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import UserBookingDetail from '@/components/User-BookingDetail'
import router from '@/router'

export default {
  components: { UserBookingDetail },
  props: {
    product: String,
    stock: Array
  },
  created() {
    this.getAllBookingsForUser(), this.getOrganisations()
  },
  computed: {
    ...mapState('translations', ['languages', 'translations']),
    ...mapState('authentication', ['userLang']),
    ...mapState('adminBookings', ['userActiveBookings'])
  },
  methods: {
    ...mapActions('adminBookings', ['getAllBookingsForUser']),
    ...mapMutations('adminBookings', [
      'setEditingBooking',
      'setSelectedOrgForBooking',
      'setSendingBooking',
      'resetDateAsSelected'
    ]),
    ...mapMutations('adminOrganisations', [
      'setSelectedLocation'
    ]),
    ...mapActions('adminUsers', ['setLanguage']),
    ...mapActions('adminOrganisations', ['getOrganisations']),
    nav({ path: path, editing: editing }) {
      if (path === '/booking' && editing === null) {
        this.resetDateAsSelected()
        this.setSendingBooking(false)
        this.setEditingBooking(editing)
        this.setSelectedOrgForBooking(editing)
        this.setSelectedLocation(null)
      }
      router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/admin.scss';
.selectedProduct {
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
}
</style>
