var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-container',[_c('p',{staticClass:"admin-form__label"},[_vm._v("Stock")]),_c('admin-products-stock'),(_vm.productToCreate.default.org.value !== null)?[_c('v-row',{attrs:{"align":"center"}},_vm._l((_vm.productToCreate.default),function(field,fieldName){return _c('v-col',{key:'default-' + fieldName,attrs:{"cols":"12","sm":"12","md":"12"}},[(field)?[(field.type == 'string')?[_c('p',{staticClass:"admin-form__label"},[_vm._v(_vm._s(field.label[_vm.userLang]))]),_vm._l((_vm.orderedLanguages),function(l,lang){return _c('v-text-field',{key:l.id,attrs:{"label":l.name,"placeholder":field.label[_vm.userLang],"value":_vm.productToCreate.default[fieldName].value[lang],"outlined":""},on:{"input":function($event){return _vm.setProductToCreate({
                    value: $event,
                    fieldName: fieldName,
                    lang: l.id
                  })}}})})]:_vm._e(),(field.type == 'number')?[_c('p',{staticClass:"admin-form__label"},[_vm._v(_vm._s(field.label[_vm.userLang]))]),_c('v-text-field',{attrs:{"label":field.label[_vm.userLang],"placeholder":field.label[_vm.userLang],"value":_vm.productToCreate.default[fieldName].value,"outlined":""},on:{"input":function($event){return _vm.setProductToCreate({
                    value: $event,
                    fieldName: fieldName,
                    lang: null
                  })}}})]:_vm._e(),(field.type === 'file')?[_c('v-row',{attrs:{"align":"start"}},[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('upload',{on:{"uploaded":_vm.uploaded}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[(field.value)?_c('v-img',{attrs:{"src":field.value}}):_vm._e()],1)],1)]:_vm._e()]:_vm._e()],2)}),1),_c('v-row',{attrs:{"align":"center"}},[_c('p',{staticClass:"admin-form__label"},[_vm._v("Specs")]),(_vm.productToCreate.specs.length == 0)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('p',{staticClass:"admin-zeroContent__text"},[_c('copy',{attrs:{"item":"addProductNoSpecs","lang":_vm.userLang}})],1)]):_vm._e()],1),_c('v-row',_vm._l((_vm.productToCreate.specs),function(field,fieldName){return _c('v-card',{key:'specs-' + fieldName,staticStyle:{"width":"100%","margin-bottom":"1rem"},attrs:{"outlined":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},_vm._l((_vm.orderedLanguages),function(l,lang){return _c('v-text-field',{key:lang,attrs:{"label":l.name,"placeholder":field.label[_vm.userLang],"value":_vm.productToCreate.specs[fieldName].label[lang],"outlined":""},on:{"input":function($event){return _vm.setProductSpecToCreate({
                      value: $event,
                      fieldName: fieldName,
                      label: true,
                      lang: lang
                    })}}})}),1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(field.i18n)?_vm._l((_vm.orderedLanguages),function(l,lang){return _c('v-text-field',{key:lang,attrs:{"label":l.name,"placeholder":field.value[_vm.userLang],"value":_vm.productToCreate.specs[fieldName].value[lang],"outlined":""},on:{"input":function($event){return _vm.setProductSpecToCreate({
                        value: $event,
                        fieldName: fieldName,
                        label: false,
                        lang: lang,
                      })}}})}):[_c('v-text-field',{attrs:{"label":field.typeLabel[_vm.userLang],"placeholder":field.value[_vm.userLang],"value":_vm.productToCreate.specs[fieldName].value,"outlined":""},on:{"input":function($event){return _vm.setProductSpecToCreate({
                        value: $event,
                        fieldName: fieldName,
                        label: false,
                        lang: null
                      })}}})]],2)],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){return _vm.removeSpec(fieldName)}}},[_c('copy',{attrs:{"item":"remove","lang":_vm.userLang}})],1)],1)],1)}),1),_c('admin-products-spec-add')]:_vm._e()],2),(
      _vm.productFunction == 'edit' && _vm.productToCreate.default.org.value != null
    )?[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","text":""},on:{"click":_vm.saveProduct}},[_c('copy',{attrs:{"item":"saveProduct","lang":_vm.userLang}})],1)]:_vm._e(),(
      _vm.productFunction == 'create' && _vm.productToCreate.default.org.value != null
    )?[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","text":""},on:{"click":_vm.addProduct}},[_c('copy',{attrs:{"item":"addProduct","lang":_vm.userLang}})],1)]:_vm._e(),_c('v-btn',{staticClass:"ma-2",attrs:{"depressed":"","small":"","color":"error","text":""},on:{"click":function($event){return _vm.deletePrompt()}}},[_c('copy',{attrs:{"item":"remove","lang":_vm.userLang}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }