<template>
  <v-dialog v-model="dialog" persistent>
    <template v-if="edit" v-slot:activator="{ on }">
      <v-icon small v-on="on" @click="setLocationToEdit(location)">
        mdi-pencil
      </v-icon> 
    </template>

    <template v-else v-slot:activator="{ on }">
      <v-btn
        class="ma-2"
        outlined
        v-on="on"
        @click="setLocationToCreateToDefaults"
      >
        <copy item="addLocation" :lang="userLang"></copy>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        <copy item="addLocation" :lang="userLang"></copy>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col
                v-for="(field, fieldName) in locationToCreate"
                v-if="
                  fieldName != 'createTimestamp' ||
                    fieldName != 'updateTimeStamp'
                "
                :key="'default-' + fieldName"
                cols="12"
                sm="12"
                md="12"
              >
                <template v-if="field.i18n && field.label != undefined">
                  <p class="admin-form__label">{{ field.label[userLang] }}</p>

                  <v-textarea
                    v-for="(l, lang) in languages"
                    :key="lang"
                    :label="l.name"
                    :placeholder="field.label[userLang]"
                    :value="locationToCreate[fieldName].value[lang]"
                    outlined
                    @input="
                      setLocationToCreate({
                        value: $event,
                        fieldName: fieldName,
                        lang: lang
                      })
                    "
                  ></v-textarea>
                </template>
                <template v-else-if="field.label != undefined">
                  <p class="admin-form__label">{{ field.label[userLang] }}</p>
                  <v-textarea
                    :label="field.label[userLang]"
                    :placeholder="field.label[userLang]"
                    :value="locationToCreate[fieldName].value"
                    outlined
                    @input="
                      setLocationToCreate({
                        value: $event,
                        fieldName: fieldName,
                        lang: null
                      })
                    "
                  ></v-textarea>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          class="ma-2"
          color="error"
          outlined
          @click="cancelAddingLocation"
        >
          <copy item="cancel" :lang="userLang"></copy>
        </v-btn>
        <div class="flex-grow-1"></div>
        <v-btn
          v-if="edit"
          class="ma-2"
          outlined
          text
          @click="updateLocationAndClose"
        >
          <copy item="saveProduct" :lang="userLang"></copy>
        </v-btn>
        <v-btn
          v-else
          class="ma-2"
          outlined
          text
          @click="createLocationAndClose"
        >
          <copy item="add" :lang="userLang"></copy>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import AdminOrganisationsLocationsDetail from '@/components/Admin-OrganisationsLocationsDetail'

export default {
  components: {
    AdminOrganisationsLocationsDetail
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    location: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapState('translations', ['languages']),
    ...mapState('authentication', ['userLang']),
    ...mapState('app', ['networkOnLine']),
    ...mapState('adminOrganisations', [
      'locationDefaults',
      'orgLocations',
      'locationToCreate'
    ])
    // ...mapGetters('adminOrganisations', ['getLocationById']),
  },
  methods: {
    ...mapMutations('adminOrganisations', [
      'setLocationToCreate',
      'setLocationToEdit',
      'setLocationToCreateToDefaults'
    ]),
    ...mapActions('adminOrganisations', [
      'createLocation',
      'getLocations',
      'updateLocation'
    ]),
    cancelAddingLocation() {
      this.dialog = false
    },
    createLocationAndClose() {
      this.dialog = false
      this.createLocation()
    },
    updateLocationAndClose() {
      this.dialog = false
      this.updateLocation()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
