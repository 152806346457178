var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.bookingHeaders,"items":_vm.activeBookingsList,"items-per-page":20,"multi-sort":""},scopedSlots:_vm._u([{key:"item.value[0]",fn:function(props){return [_c('v-edit-dialog',{on:{"save":function($event){return _vm.save({ value: _vm.editValue, lang: 0, ref: props.item.ref })},"cancel":function($event){return _vm.cancel()},"open":function($event){return _vm.open()},"close":function($event){return _vm.close()}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"value":props.item.value[0],"label":"Edit","single-line":"","counter":""},on:{"input":function($event){_vm.editValue = $event}}})]},proxy:true}],null,true)},[_vm._v("\n            "+_vm._s(props.item.value[0])+"\n            ")])]}},{key:"item.value[1]",fn:function(props){return [_c('v-edit-dialog',{on:{"save":function($event){return _vm.save({ value: _vm.editValue, lang: 1, ref: props.item.ref })},"cancel":function($event){return _vm.cancel()},"open":function($event){return _vm.open()},"close":function($event){return _vm.close()}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"value":props.item.value[1],"label":"Edit","single-line":"","counter":""},on:{"input":function($event){_vm.editValue = $event}}})]},proxy:true}],null,true)},[_vm._v("\n            "+_vm._s(props.item.value[1])+"\n            ")])]}},{key:"item.dateRange",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm._f("moment")(item.dateRange.start,'D MMMM YYYY'))+"\n          "),(!_vm.isSameDate(item.dateRange.start, item.dateRange.end))?_c('span',[_vm._v("- "+_vm._s(_vm._f("moment")(item.dateRange.end,'D MMMM YYYY')))]):_vm._e()]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [(_vm.getUserById(item.userId))?_c('span',[_vm._v("\n            "+_vm._s(_vm.getUserById(item.userId).firstName.value)+"\n            "+_vm._s(_vm.getUserById(item.userId).lastName.value)+"\n          ")]):_vm._e()]}},{key:"item.school",fn:function(ref){
var item = ref.item;
return [(_vm.getUserById(item.userId))?_c('span',[_vm._v("\n            "+_vm._s(_vm.getUserById(item.userId).school.value)+"\n          ")]):_vm._e()]}},{key:"item.locations",fn:function(ref){
var item = ref.item;
return _vm._l((item.locations),function(location){return _c('div',{key:location},[_vm._v("\n            "+_vm._s(_vm.getLocationById(location).name.value[_vm.userLang])+"\n          ")])})}},{key:"item.toDelete",fn:function(ref){
var item = ref.item;
return [(item.toDelete)?_c('span',[_vm._v(_vm._s(item.toDelete))]):_vm._e()]}},{key:"item.timeslots",fn:function(ref){
var item = ref.item;
return _vm._l((item.timeslots),function(timeslot,key){return _c('div',{key:("timeslot-" + key)},[(timeslot.value)?_c('span',[_vm._v("\n              "+_vm._s(timeslot.name[_vm.userLang])+"\n            ")]):_vm._e()])})}},{key:"item.selectedStock",fn:function(ref){
var item = ref.item;
return _vm._l((item.selectedStock),function(stock,product){return _c('div',{key:product},[(_vm.getProductById(product))?_c('div',[_vm._v("\n              "+_vm._s(_vm.getProductById(product).default.name.value[_vm.userLang])+":\n              "+_vm._s(stock.length)+"\n            ")]):_vm._e()])})}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deletePrompt(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }