var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.users === null)?_c('p',{staticClass:"infos-label"},[_c('copy',{attrs:{"item":"loading","lang":_vm.userLang}})],1):_vm._e(),(_vm.users && !_vm.users.length)?_c('p',{staticClass:"infos-label"},[_c('copy',{attrs:{"item":"noUsersInList","lang":_vm.userLang}})],1):_vm._e(),(_vm.users == null)?_c('div',[_c('copy',{attrs:{"item":"noUsersInList","lang":_vm.userLang}})],1):_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.usersFiltered,"sort-by":['name'],"sort-desc":[false],"multi-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_c('copy',{attrs:{"item":"users","lang":_vm.userLang}})],1),_c('div',{staticClass:"flex-grow-1"}),_c('admin-users-detail',{attrs:{"edit":false}})],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('admin-users-detail',{attrs:{"edit":true,"user":item}}),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deletePrompt(item)}}},[_vm._v("\n        mdi-delete\n      ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.sendMagicLink({ email: item.email.value })}}},[_vm._v("\n        mdi-email\n      ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(item.email)?_c('span',[_vm._v("\n        "+_vm._s(item.email.value)+"\n      ")]):_vm._e()]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [(item.firstName)?_c('span',[_vm._v("\n        "+_vm._s(item.firstName.value)+" \n      ")]):_vm._e()]}},{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [(item.lastName)?_c('span',[_vm._v("\n        "+_vm._s(item.lastName.value)+"\n      ")]):_vm._e()]}},{key:"item.school",fn:function(ref){
var item = ref.item;
return [(item.school)?_c('span',[_vm._v("\n        "+_vm._s(item.school.value)+"\n      ")]):_vm._e()]}},{key:"item.language",fn:function(ref){
var item = ref.item;
return [(item.lang)?_c('span',[_vm._v("\n        "+_vm._s(item.lang.value.locale)+"\n      ")]):_vm._e()]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [(item.organisations)?_c('span',_vm._l((item.organisations),function(i){return _c('div',[_vm._v("\n          "+_vm._s(_vm.getUserRoleById(i).name[_vm.userLang])+"\n        ")])}),0):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }