<template>
  <v-dialog v-model="dialog" persistent>
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="!edit"
        class="ma-2"
        outlined
        v-on="on"
        @click="startCreatingNewUser"
      >
        <copy item="addUser" :lang="userLang"></copy>
      </v-btn>
      <v-icon v-else small v-on="on" @click="setUserToEdit(user)">
        mdi-pencil
      </v-icon>
    </template>

    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        <span v-if="!edit">
          <copy item="addUser" :lang="userLang"></copy>
        </span>
        <span v-else>
          <copy item="editUser" :lang="userLang"></copy>
        </span>
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-container>
            <v-row align="center">

              <v-col
                v-for="(field, fieldName) in selectedUser"
                :key="'default-' + fieldName"
                cols="12"
                sm="12"
                md="12"
              >
                <template v-if="field.type == 'select'">
                  <v-select
                    :value="selectedUser[fieldName].value"
                    :items="languages"
                    :label="field.label[userLang]"
                    :item-value="fieldName"
                    outlined
                    @input="
                      setSelectedUser({
                        value: $event,
                        fieldName: fieldName
                      })
                    "
                  >
                    <template v-slot:selection="{ item, id }">
                      {{ item.name }}
                    </template>
                    <template v-slot:item="{ item, id }">
                      {{ item.name }}
                    </template>
                  </v-select>
                </template>
                <template v-if="field.label && field.type != 'select'">
                  <p class="admin-form__label">{{ field.label[userLang] }}</p>

                  <v-text-field
                    :placeholder="field.label[userLang]"
                    :value="selectedUser[fieldName].value"
                    outlined
                    @input="
                      setSelectedUser({
                        value: $event,
                        fieldName: fieldName
                      })
                    "
                  ></v-text-field>
                </template>
              </v-col>
            </v-row>
            <admin-users-organisations></admin-users-organisations>
            <!-- <copy v-else item="saveProduct" :lang="userLang"></copy> -->
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn class="ma-2" color="error" outlined @click="dialog = false">
          <copy item="cancel" :lang="userLang"></copy>
        </v-btn>
        <div class="flex-grow-1"></div>
        {{ edit }}
        <template v-if="edit">
          <v-btn class="ma-2" outlined text @click="updateUserAndCloseDialog">
            <copy item="saveUser" :lang="userLang"></copy>
          </v-btn>
        </template>

        <template v-else>
          <v-btn class="ma-2" outlined text @click="addUserAndCloseDialog">
            
            <copy item="addUser" :lang="userLang"></copy>
          </v-btn>
        </template>

      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import AdminUsersOrganisations from '@/components/Admin-UsersOrganisations'

export default {
  components: { AdminUsersOrganisations },
  props: {
    edit: Boolean,
    user: Object
  },
  data() {
    return {
      dialog: false,
      userLanguage: null
    }
  },
  computed: {
    ...mapState('adminUsers', ['selectedUser']),
    ...mapState('translations', ['languages']),
    ...mapState('authentication', ['userLang']),
    ...mapGetters('translations', ['copy'])
  },
  methods: {
    ...mapMutations('adminUsers', ['setSelectedUser', 'setUserToEdit']),
    ...mapActions('adminUsers', [
      'addUser',
      'updateUser',
      'startCreatingNewUser'
    ]),
    addUserAndCloseDialog() {
      // TODO: Passsing the v-model of the userRole and userLanguage, this should be in the vuex way
      // https://trello.com/c/iFmjbk4C
      this.addUser({
        user: this.selectedUser,
        lang: this.userLanguage
      })
      this.dialog = false
    },
    updateUserAndCloseDialog() {
      this.updateUser()
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/admin.scss';
</style>
